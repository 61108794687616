export const getKeyByValueFromObj = (obj) => {
  return (value) => {
    // will return the categroyName if we pass categoryID
    return Object.keys(obj).find((key) => obj[key] == value);
  };
};

export const countRepeatingItems = (arr) => {
  return arr.reduce((count, item) => {
    count[item] = (count[item] || 0) + 1;
    return count;
  }, {});
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
