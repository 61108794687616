import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import {
  pricesForRentalDetails,
  propertyTypes,
} from "../../constants/pricingDetails";
import {
  countRepeatingItems,
  getKeyByValueFromObj,
} from "../../functions/helperFunction";
import { wishlistItemCount } from "../../Contexts/WishListContext";

const getKeyByValue = getKeyByValueFromObj(propertyTypes);

const OrderDetails = memo(() => {
  const { data } = useContext(wishlistItemCount);
  const [showDetails, setShowDetails] = useState(false);
  const itemsFromWishlist = useMemo(() => Array.from(data), [data]);

  const toggleDetailsPopup = useCallback(() => {
    setShowDetails((prevState) => !prevState);
  }, [setShowDetails]);

  const orderSummery = useMemo(() => {
    const categories = itemsFromWishlist.map((item) => Number(item.categoryId));
    const totalAmount =
      data.size > 5
        ? categories.reduce((acc, currentValue) => {
            return acc + pricesForRentalDetails[currentValue];
          }, 0)
        : pricesForRentalDetails[Math.max(...categories)] * 5;
    return { totalAmount, categories };
  }, [data, pricesForRentalDetails]);
  const orderDetails = useMemo(() => {
    const repeatedCategoryObject = countRepeatingItems(orderSummery.categories);
    return Object.keys(repeatedCategoryObject).map((item, index) => {
      const priceOFCategory = pricesForRentalDetails[item];
      return (
        <div
          key={index}
          className="d-flex justify-content-between fs-6 fw-bold"
        >
          <div className="categoryName">{getKeyByValue(item)}</div>
          <div>
            {priceOFCategory} x {repeatedCategoryObject[item]} ={" "}
            {repeatedCategoryObject[item] * priceOFCategory}
          </div>
        </div>
      );
    });
  }, [data]);
  return (
    <div
      className="bg-white w-100 p-3 shadow position-relative"
      style={{ maxWidth: "554px", minWidth: "333px", borderRadius: 19.29 }}
    >
      {showDetails && (
        <div
          className="position-absolute bg-white shadow overflow-hidden"
          style={{
            top: 0,
            right: 0,
            width: "90%",
            borderRadius: "2rem",
            zIndex: 10,
          }}
        >
          <div
            className="text-end pe-4 cursor-pointer"
            onClick={toggleDetailsPopup}
            style={{ backgroundColor: "rgba(0,0,0,0.25)", fontSize: 30 }}
          >
            &times;
          </div>
          <p className="p-1 px-4">
            The price is fixed upto 5 videos. For orders exceeding 5 videos,
            additional price will be charged as per the property.
          </p>
        </div>
      )}
      <p className="d-flex justify-content-between" role="heading">
        <span className="fw-bold fs-5">Bill Details</span>
        {data.size <= 5 && (
          <span
            className="d-flex align-items-center gap-2 cursor-pointer"
            onClick={toggleDetailsPopup}
          >
            Fixed price
            <span
              className="rounded-circle text-white"
              style={{
                backgroundColor: "rgba(0,0,0,0.35)",
                fontSize: 14,
                padding: "2px 8px",
              }}
            >
              ?
            </span>
          </span>
        )}
      </p>
      {data.size > 5 && <div>{orderDetails}</div>}
      <hr />
      <p className="d-flex justify-content-between w-100 fw-bolder">
        <span>To Pay</span>{" "}
        <span className="text-success" style={{ letterSpacing: 2 }}>
          ₹{orderSummery.totalAmount}
        </span>
      </p>
      <div
        className="savingAmtSection text-success rounded-pill d-flex justify-content-between align-items-center px-3 p-2 fw-bold"
        style={{ backgroundColor: "rgba(28, 166, 114, 0.19)" }}
      >
        <div>Your total savings</div>
        <div>₹1000</div>
      </div>
    </div>
  );
});

export default OrderDetails;
