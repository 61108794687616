import BackArrow from "./assets/BackArrow.svg";
import { useNavigate } from "react-router-dom";

function PaymentHeader() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <div style={{ flex: 1, maxHeight: "fit-content" }}>
      <div
        className="py-3 d-flex align-items-center align-items-center w-100 position-relative"
        style={{
          backgroundColor: "#F2F1EF",
          flex: 1,
          maxHeight: "fit-content",
        }}
      >
        <button
          onClick={goBack}
          className="rounded-circle ps-sm-5 ps-3 start-0 position-absolute top-50"
          style={{outline: "none", border: "none", transform: "translateY(-50%)"}}
        >
          <img
            src={BackArrow}
            alt="Back"
            style={{ minWidth: 40, maxWidth: 60, width: "5vw" }}
          />
        </button>
        <div
          className="fw-bold text-dark w-100 text-center"
          style={{ fontSize: "clamp(28px, 10vw, 40px)" }}
        >
          CheckOut
        </div>
      </div>

      <p
        className="text-dark py-2 w-100 shadow-sm text-center"
        style={{
          backgroundColor: "#E7A03C14",
          fontSize: "clamp(12px, 2vw, 18px)",
          boxShadow: "rgba(231, 160, 60, 0.14) 0px 5px 14px",
        }}
      >
        Pay and Receive Owners’ Contact Details | Purchase Summary
      </p>
    </div>
  );
}

export default PaymentHeader;
