import React, { memo, useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import CartItemDetails from "./CartItemDetails";

const BrokerOwnerDetails = memo(() => {
  const location = useLocation();
  const { data } = useContext(wishlistItemCount);
  const dataArray = useMemo(() => Array.from(data), [data]);

  const findBrokerById = useCallback(
    (brokerId) => {
      return location.state?.find((broker) => broker.brokerid == brokerId);
    },
    [location.state]
  );

  console.log(location);
  return (
    <div>
      <Header />
      <div
        className="text-center fw-bold py-2"
        style={{ fontSize: "clamp(24px, 8vw, 50px)" }}
      >
        Broker & Owner Details
      </div>
      {dataArray.map((item) => (
        <CartItemDetails
          data={item}
          key={item.inventroyId}
          ownerBrokerDetails={findBrokerById(item.brokerId)}
        />
      ))}
    </div>
  );
});

export default BrokerOwnerDetails;
