import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Shimmerui from "./Shimmerui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import AOS from "aos";
import Select from "react-select";
import $ from "jquery";
import "aos/dist/aos.css"; // You can also use <link> for styles
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Content from "./Content";
import { FairPocketApi } from "../api/FairPockets_api";
import { BizMagnetApi } from "../api/BizMagnet_Api";
import { Helmet } from "react-helmet-async";
import { postApiFunction } from "../api/Post_api";
import { API_BASE_URL } from "../constants/Service";
import { base_url } from "../constants/Service";
import { toastProperties } from "../helper/toastifyProperties";

AOS.init();
const Product = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [videoData, setVideoData] = useState([]);
  const [videoFilterData, setVideoFilterData] = useState([]);
  const { categoryId } = useParams();
  const pageTitle = `${categoryId.toUpperCase()}`;
  const canonicalUrl = `${base_url}/${categoryId}`;
  const isSmallScreen = window.innerWidth < 700;
  const [CityData, setCityData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("4"); // Initialize with an empty string
  const [selectedAreaId, setSelectedAreaId] = useState(""); // Initialize with an empty string
  const [AreaData, setAreaData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [minAmount, setMinAmount] = useState("500");
  const [maxAmount, setMaxAmount] = useState("500000");
  const [stepAmount, setStepAmount] = useState("5000");
  const [propsId, setPropsId] = useState(""); // Initialize with the value of props.selectedId
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [Results, setResults] = useState([]);
  const [howMany, setHowMany] = useState([]);
  const [value, setValue] = React.useState([]);
  const [name, setName] = useState([]);
  const [number, setNumber] = useState([]);
  const [nameOfCategories, setNameOfCategories] = useState([]);
  const [submitButtonColor, setSubmitButtonColor] = useState("#eb8500");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [brokerId, setBrokerId] = useState([]);
  const [defaultName, setDefaultName] = useState([]);
  const [defaultId, setDefaultId] = useState([]);
  const [otherContent, setOtherContent] = useState("");
  const [scrollBtn, setScrollBtn] = useState(false);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const scrollToVideoSection = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const footerRef = useRef(null);
  const [perDay, setPerDay] = useState(false);
  const [status, setStatus] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async (catID, offset = 0) => {
    try {
      let id =
        catID === undefined || catID === "12" || categoryId === undefined
          ? ""
          : catID;
      if (catID === "12") {
        id = "";
      }
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getDataInventory",
          categoryId: `${id}`,
          limit: "30",
          offset: `${offset}`,
        }
      );
      // console.log("datafetchvideos", data);
      if (data.data.status === "Success") {
        setVideoFilterData((prevData) => [...prevData, ...data?.data?.data]);
        setHowMany(data.data.inventroy_count);
        // console.log("totalinventory", data.data.inventroy_count);
        setVideoData(data.data.data);
        setStatus(true);
        // console.log("Fetch Data Api Calling.....", catID);
      } else {
        setVideoFilterData([]);
        setHowMany(0);
        setVideoData([]);
        setStatus(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMore = async (offset) => {
    try {
      if (videoFilterData.length >= howMany) {
        // console.log("All data has been loaded");
        setHasMore(false);
        return; // No more data to load
      }
      const newOffset = offset + 30;
      await fetchData(defaultId, newOffset); // Assuming defid is the category ID
    } catch (error) {
      console.error("Error loading more data:", error);
    }
  };

  const shareContent = () => {
    const message = `${window.location.href}`;

    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
  };

  // Function to handle scroll to footer
  const scrollToFooter = () => {
    if (scrollBtn == false) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollBtn(true);
    } else {
      window.scrollTo(0, 0);
      setScrollBtn(false);
    }
  };

  useEffect(() => {
    if (defaultId.length !== 0) {
      Sliders();
    }
  }, [defaultId]);

  const Sliders = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "sliders",
          column: "CategoryId",
          value: `${defaultId}`,
        }
      );

      console.log("dataSlider", response);

      // Filter sliders with slider_type "home"
      if (response.data.status !== "false") {
        setProduct(response.data.data);
        setOtherContent(response.data.other_content);
      } else {
        setProduct([]);
        setOtherContent([]);
      }
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  // ("categoryId", categoryId.replace(/-/g, " "));

  useEffect(() => {
    fetchDataCity();
    fetchDataArea();
  }, []);

  useEffect(() => {
    fetchcategorys();
  }, [categoryId]);

  const fetchcategorys = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const filteredData = data?.data?.data;

    // Remove the first element from the filtered data

    setCategory(filteredData);
    // console.log("categoryId", categoryId.toUpperCase());

    const categoryItem = filteredData.find(
      (item) => item.name.toLowerCase() === categoryId.replace(/-/g, " ")
    );
    const categoryIds = categoryItem ? categoryItem.categoryId : null;

    if (categoryIds != null) {
      setDefaultId(categoryIds);
      // console.log("categoryItem", categoryItem);

      if (categoryId) {
        // Assuming your URL format is consistent with "nameId" (e.g., "categoryName123")
        const categoryName = categoryId.replace(/-/g, " ");

        setDefaultName(categoryName);

        // Log the category name and ID
        // console.log("Category Name:", categoryName);

        // if(categoryId !== "5-bhk--&-above"){
        //   const categoryItem = filteredData.find(item => item.name.toLowerCase() === categoryId.replace( '-' ," "));
        //   const categoryIds = categoryItem ? categoryItem.categoryId : null;
        //   console.log("categoryItem", categoryItem);
        //   setdefaululid(categoryIds)
        // }
        // else{
        //   categoryId.replace( '5-bhk--&-above' ," 5 bhk & above")
        //   setdefaululid("8")
        // }
      }
    } else {
      navigation("/");
    }
  };

  useEffect(() => {
    if (defaultId.length !== 0) {
      fetchData(defaultId);
    }
  }, [defaultId]);

  const [originalButtonColor, setOriginalButtonColor] = useState("#eb8500");
  useEffect(() => {
    // Set the original button color when the component mounts
    setOriginalButtonColor(""); // Set your original color here
  }, []);

  // console.log("NameOfCategoerys", nameOfCategories);

  // console.log("Results", Results);

  // console.log("videoFilterDatssa", videoFilterData);

  // console.log("setSelectedCategoryName", selectedCategoryName);

  // console.log("propsid", propsId);

  // console.log("maxAmount", maxAmount);

  const handleCityChange = (selectedOption) => {
    setSelectedCityId(selectedOption.value);
  };

  const handleAreaChange = (selectedOptions) => {
    setSelectedAreaId(selectedOptions.map((option) => option.value));
    // console.log(
    //   "areas",
    //   selectedOptions.map((option) => option.value)
    // );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryIds(selectedOption.value);
      setSelectedCategoryName(selectedOption.label);
    }
  };

  const handleFilter = async () => {
    try {
      // Set propsid to an empty string

      setLoading(true);

      // console.log("111", categoryIds);

      setPropsId("");
      if (selectedCategoryName === "") {
        setNameOfCategories(selectedCategoryName);
      } else {
        setNameOfCategories(selectedCategoryName); // Use selectedCategoryName directly
      }

      // Create the request object
      const request = {
        method: "getDataInventory",
        categoryId: categoryIds === "12" ? "" : `${categoryIds}`,
        areaId: `${selectedAreaId}`,
        rent: `${value[0]},${value[1]}`,
        cityId: `${selectedCityId}`,
      };
      // console.log("handleFilter", request);
      // Make the API call
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        request
      );
      // console.log("selectedCategoryName", selectedCategoryName);

      // Always update the category name, regardless of the API response

      // Check the response status

      if (response.data.status === "Success") {
        setVideoFilterData(response.data.data);
        setHowMany(response.data.data.length);
        setLoading(false);
        scrollToVideoSection.current.scrollIntoView({ behavior: "smooth" });
      } else if (response.data.status === "false") {
        // Handle the case when no data is found
        setHowMany("No");
        setLoading(false);
        setVideoFilterData([]);
        toast.error("No Data Found", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error--->", error);
      // Handle the error, e.g., show an error message.
    }
  };

  const fetchDataCity = async () => {
    try {
      const result = await postApiFunction(
        "getData",
        "city",
        "status",
        "1",
        null,
        null,
        API_BASE_URL
      );
      if (result.status === "Success") {
        setCityData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataArea = async () => {
    try {
      const result = await postApiFunction(
        "getData",
        "area",
        "cityId,status",
        `${selectedCityId},1`,
        "sort_order",
        "asc",
        null,
        API_BASE_URL
      );
      if (result.status === "Success") {
        setAreaData(result.data);
      } else {
        setAreaData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataArea();
  }, [selectedCityId]);

  useEffect(() => {
    if (categoryIds.length > 0) {
      const selectedCategoriesData = category.filter((cat) =>
        categoryIds.includes(cat.categoryId)
      );

      if (selectedCategoriesData.length > 0) {
        const allMaxValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.max_amount)
        );
        const allMinValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.min_amount)
        );

        const maxAmount = Math.max(...allMaxValues);
        const minAmount = Math.min(...allMinValues);

        // Set default values if minAmount is null or empty
        const finalMinAmount = minAmount || 5000;

        // Set default values if maxAmount is null or empty
        const finalMaxAmount = maxAmount || 500000;

        setValue([finalMinAmount, finalMaxAmount]);
        setMaxAmount(finalMaxAmount);
        setMinAmount(finalMinAmount);

        // console.log("selectedCategoriesData", selectedCategoriesData);
        // console.log("allMinValues", allMinValues);
        // console.log("allMaxValues", allMaxValues);
        // console.log("minAmount", finalMinAmount);
        // console.log("maxAmount", finalMaxAmount);
        // console.log("setValue", categoryIds);
      } else {
        // Handle the case when no categories are selected
        setDefaultValues();
      }
    } else {
      setDefaultValues();
    }
  }, [categoryIds, category]);

  const setDefaultValues = () => {
    console.log("No categories selected");
  };

  // console.log("selectadedatabr", brokerId);

  useEffect(() => {
    // Set the default value to [1] if categoryIds is an empty array
    if (categoryIds.length === 0) {
      setCategoryIds(defaultId);

      // console.log("125", defaultId);
    }
  }, [defaultId]);

  const SendData = async () => {
    if (loader) {
      return;
    }

    const request = {
      method: "setData",
      table: "enquiry",
      data: {
        name: `${name}`,
        brokerId: `${selectedData.brokerId}`,
        number: `${number}`,
        shifting_date: `${selectedDate}`,
        inventroyId: `${selectedData.inventroyId}`,
      },
    };
    // console.log("requestSendDatass", request);

    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    if (number.length < 1) {
      toast.error("Enter Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    setLoader(true);
    const formSubmitData = {
      name: name,
      brokerId: selectedData.brokerId,
      number: number,
      shifting_date: selectedDate,
      inventroyId: selectedData.inventroyId,
    };

    const FairPocketApiData = {
      name: name,
      mobile: number,
      email: "", // You may leave it as an empty string if not provided
      project: selectedData.category_name,
      source: "4",
      requirement: `<br><b>Inventory Id:</b> ${selectedData.inventroyId},<br>
            <b>Title:</b> ${selectedData.title},<br>
            <b>Broker Name:</b> ${selectedData.broker_name},<br>
            <b>City Name:</b> ${selectedData.city_name},<br>
            <b>Rent:</b> ${selectedData.rent},<br>
            <b>Furniture Type:</b> ${selectedData.furniture_type},<br>
            <b>Amenities:</b> ${selectedData.admin_remark}`,
    };

    const bizMagnetDataClient = {
      name: name,
      number: number,
      text: selectedData.broker_number,
      title: selectedData.title,
      data_to_send: "client",
    };

    const bizMagnetDataBroker = {
      name: selectedData.broker_name,
      number: selectedData.broker_number,
      text: number,
      title: selectedData.title,
      data_to_send: "broker",
    };

    try {
      const formDataSubmitApi = await postApiFunction(
        "setData",
        "enquiry",
        null,
        null,
        null,
        null,
        formSubmitData
      );

      await FairPocketApi(FairPocketApiData);

      await BizMagnetApi(bizMagnetDataClient);

      await BizMagnetApi(bizMagnetDataBroker);

      if (formDataSubmitApi?.status === "Success") {
        setSubmitButtonColor("#006622");
        setFormSubmitted(true);
        toast.success("Success", toastProperties);
        setName("");
        setNumber("");
        setSelectedDate(new Date());
        setTimeout(() => {
          setSubmitButtonColor("#eb8500");
          setFormSubmitted(false);
        }, 4000);
      } else {
        alert(false);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    // console.log("currentUrl", currentUrl);
    if (currentUrl.includes("id")) {
      setModalOpen(true);
      // console.log("yes it has id in url ");

      const idParam = new URLSearchParams(window.location.search).get("id");

      const idNumber = idParam ? parseInt(idParam.replace(/\D/g, "")) : null;

      if (!isNaN(idNumber)) {
        console.log("Number from URL:", idNumber);
      } else {
        console.log("No valid number found in URL");
      }

      const fetchVideoById = async () => {
        try {
          const data = await axios.post(
            "https://bookmyqrcode.com/home_rental/api/api.php",
            {
              method: "getDataInventory",

              column: "inventroyId",
              value: `${idNumber}`,
            }
          );
          // console.log("databyid", data.data.data[0]);

          setSelectedData(data.data.data[0]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchVideoById();
    }
  }, [window.location.href]);
  const openModal = (data) => {
    setSelectedData(data);
    setModalOpen(true);

    const newcurrentUrl = new URL(window.location.href);
    newcurrentUrl.searchParams.set("id", data.inventroyId);
    window.history.pushState(null, "", newcurrentUrl.href);
  };

  const handleModalCloseByButton = () => {
    window.history.back();
    setModalOpen(false);
  };

  window.onpopstate = () => {
    window.onpopstate = () => {};
    setModalOpen(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", () => {
      setModalOpen(false);
    });
  });

  if (!status) {
    return <Shimmerui />;
  }
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div>
        <a
          href="https://wa.me/919990025112"
          target="_blank"
          className="fixed-button-whatsapp"
        >
          <img
            src="Assets/image/whatsapp.png"
            alt=""
            style={{ height: "35px" }}
          />
        </a>
        {!scrollBtn && (
          <button className="fixed-button z-1" onClick={scrollToFooter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-down-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
        {scrollBtn && (
          <button
            className="fixed-button secondfixed-button z-1"
            onClick={scrollToFooter}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-up-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
      </div>
      <div className="text-white ">
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {product.map((item, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
                style={{ position: "relative", height: "350px" }}
              >
                <img
                  className="sliderimg"
                  src={"https://bookmyqrcode.com/home_rental/" + item.image}
                  alt={`Slide ${index}`}
                  style={{
                    height: "350px",
                    objectFit: "cover",
                    width: "100%",
                    position: "relative",
                  }}
                />
                <div
                  className="dark-overlay"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                  }}
                ></div>
                <div
                  className="col-md-8  col-12 text-center  position-absolute start-50 translate-middle"
                  style={{ top: "50%" }}
                >
                  <h2 style={{ fontWeight: "650" }}>{item.category_name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.category_desc }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="moileornot">
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center mt-3">
        {modalOpen && (
          <div className="col-md-12 Modals ">
            <Helmet>
              <title>{selectedData.title}</title>
              <meta property="og:title" content={selectedData.title} />
              <meta
                property="og:image"
                content="https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&w=600"
              />
              {/* Add other Open Graph meta tags here */}
            </Helmet>

            <div
              className="col-md-8 modaldiv p-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="">
                <div className="card">
                  {selectedData && (
                    <div className="row g-0 ">
                      <div className="col-md-6 videodiv">
                        <iframe
                          id="videoIframe"
                          title="video"
                          src={selectedData.video_link}
                        ></iframe>
                      </div>

                      <div className="col-md-6 p-3 px-4 d-flex flex-column modal-b">
                        <div className="d-flex justify-content-between  justify-content-center my-1">
                          <p style={{ fontWeight: "600" }}>
                            {" "}
                            <strong>{selectedData.enquiryCount} </strong>
                            Interested
                          </p>

                          <svg
                            onClick={handleModalCloseByButton}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            color="#A93226"
                            fill="currentColor"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </div>

                        <div className="fills">
                          <div className="d-flex  flex-column justify-content-center col-md-12">
                            <h4 style={{ fontWeight: "650" }}>
                              {selectedData.title}
                            </h4>
                            {/* <p style={{ fontWeight: "500", fontSize: "13px" }}>
                  {selectadedata.subtitle}
                </p> */}
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center col-md-12">
                            <div
                              className="row  p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>
                                Channel Partner
                              </h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.broker_name}
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container">
                              <h5 style={{ fontWeight: "650" }}>
                                Category Type
                              </h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.category_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center ">
                            <div
                              className="row p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>City</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.city_name}
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container">
                              <h5 style={{ fontWeight: "650" }}>Area</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.area_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center ">
                            <div
                              className="row  p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>Rent</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.rent}
                                {perDay && (
                                  <strong className="mx-1">/ Per Day</strong>
                                )}
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container ">
                              <h5 style={{ fontWeight: "650" }}>
                                Furniture Type
                              </h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.furniture_type}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex  py-1 justify-content-center ">
                            <div
                              className="  p-3 px-0 text-center col-md-12 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>Amenities</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectedData.admin_remark}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  flex-column modal-forms my-3 mb-0 container justify-content-center">
                            <div className="row">
                              <div className="col-md-6 my-2">
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Name
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Your Name"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-md-6 my-2 mb-0">
                                <div className="form-outline">
                                  <label
                                    className="form-label"
                                    for="typeNumber"
                                  >
                                    Number{" "}
                                  </label>

                                  <input
                                    type="number"
                                    id="typeNumber"
                                    placeholder="Enter Your Number"
                                    className="form-control"
                                    value={number}
                                    onChange={(event) => {
                                      const limitedNumber =
                                        event.target.value.slice(0, 10); // Limit to 10 characters
                                      setNumber(limitedNumber);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex  flex-column modal-forms my-3 container justify-content-center">
                            <div className="row">
                              <div className="col-md-6 my-2">
                                <div className="form-outline">
                                  <label
                                    className="form-label"
                                    for="typeNumber"
                                  >
                                    Shifting Date{" "}
                                  </label>
                                  <DatePicker
                                    className="form-control"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    minDate={new Date()} // Set the minimum date to today
                                  />{" "}
                                </div>
                              </div>
                              <div className="col-md-6 my-2 "></div>
                            </div>
                          </div>
                          <div className="d-flex  justify-content-center align-items-center col-md-12 col-12 py-4 pt-0">
                            <div className="row col-md-12 justify-content-between">
                              <div className="  col-md-5 d-flex justify-content-center justify-content-sm-start">
                                <button
                                  className="button-51 button-50"
                                  onClick={() => {
                                    SendData();
                                  }}
                                  style={{ backgroundColor: submitButtonColor }}
                                  disabled={loader}
                                >
                                  {formSubmitted ? " Submitted" : "Interested"}
                                </button>
                                <ToastContainer />
                              </div>
                              <div
                                className="col-md-5 d-flex justify-content-center justify-content-sm-end"
                                onClick={shareContent}
                              >
                                <button className="button-50" role="button">
                                  Share
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row videosform section col-md-12">
          <div className="col-md-3 my-5">
            <div className="forms">
              <div className="form  col-md-12  ">
                <div id="mobileinner">
                  <div className="container px-2 maindiv ">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      City
                    </label>
                    <div className="City">
                      <Select
                        className=""
                        id=""
                        placeholder="Select Category"
                        options={CityData.map((City) => ({
                          label: City.name,
                          value: City.cityId,
                        }))}
                        value={
                          selectedCityId
                            ? {
                                label: CityData.find(
                                  (city) => city.cityId === selectedCityId
                                )?.name,
                                value: selectedCityId,
                              }
                            : null
                        }
                        onChange={handleCityChange}
                      />
                    </div>
                  </div>

                  <div className="container px-2 maindiv">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Product
                    </label>
                    <div className="  cityp ">
                      <div className="">
                        <Select
                          className=""
                          id=""
                          isMulti={false} // Set to false for single selection
                          placeholder="Select Product"
                          options={category.map((name) => ({
                            label: name.name,
                            value: name.categoryId,
                          }))}
                          onChange={handleSelectChange}
                          value={
                            categoryIds
                              ? {
                                  label: category.find(
                                    (name) => name.categoryId === categoryIds
                                  )?.name,
                                  value: categoryIds,
                                }
                              : null // Provide null when there is no selection
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="">
                  <div className="container px-2 maindiv mt-1 ">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label mb-1"
                    >
                      Area
                    </label>
                    <div className="Areaselectarea">
                      <Select
                        className=""
                        isMulti
                        placeholder="Select Areas"
                        options={AreaData.map((areadata) => ({
                          label: areadata.name,
                          value: areadata.areaId,
                        }))}
                        onChange={(selectedOptions) =>
                          handleAreaChange(selectedOptions)
                        }
                        value={AreaData.filter((areadata) =>
                          Array.isArray(selectedAreaId)
                            ? selectedAreaId.includes(areadata.areaId)
                            : selectedAreaId === areadata.areaId
                        ).map((areadata) => ({
                          label: areadata.name,
                          value: areadata.areaId,
                        }))}
                      />
                    </div>
                  </div>
                  <div className=" col-md-12 px-2 maindiv towmaindiv mt-2">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label mb-1"
                    >
                      Budget
                    </label>
                    <Box className="container ">
                      <Slider
                        className="roodt"
                        getAriaLabel={() => "Temperature range"}
                        defaultValue={30}
                        // getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                        value={value}
                        Slider
                        onChange={handleChange}
                        step={stepAmount}
                        marks
                        min={parseFloat(minAmount)}
                        max={parseFloat(maxAmount)}
                      />
                    </Box>
                    <div
                      className="container-fluid d-flex justify-content-between maxheight"
                      style={{ maxHeight: "15px" }}
                    >
                      <div>
                        <p>₹ {value[0]}</p>
                      </div>
                      <div>
                        <p>₹ {value[1]}</p>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center my-1">
                  <div className="buttonssdif d-flex">
                    <button onClick={handleFilter}>Search</button>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-9 "
            id="mobilediv"
            style={{ position: "relative" }}
            ref={scrollToVideoSection}
          >
            {loading && (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="shimmer-container">
                    <iframe src="https://lottie.host/embed/d9f11e0b-3586-4c27-b585-686572b5343a/CSxWt1J3W5.json"></iframe>
                    <div className="shimmer"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-8 d-flex align-items-center">
                <h3 className="text-start Heading mb-0">
                  {howMany} Results | For {nameOfCategories} Rent
                </h3>
              </div>
              <div className="col-4">
                {" "}
                {/* <div className="mb-3">
                <div className="input-group mb-3">
                  <select className="form-select" id="inputGroupSelect01">
                    <option value="" disabled selected>
                      Sort by : Relevance
                    </option>

                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}
              </div>
            </div>
            {videoFilterData.length !== 0 ? (
              <div className="d-flex col-12 videobar justify-content-start p-2 py-1">
                <div className="row">
                  <div className="d-flex col-12 videobar container-fluid">
                    <div className="row py-0 mb-0  mt-0">
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={
                          <div
                            key={0}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <iframe src="https://lottie.host/embed/c8fb0cb2-26a4-4514-b1e9-97edfb86de38/Y5o8S6w9eZ.json"></iframe>
                          </div>
                        }
                      >
                        <div className="row h-130">
                          {videoFilterData.map((item, index) => (
                            <div
                              className="col-xl-3 col-lg-6 col-md-4 col-4 p-2 modalvideo"
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                openModal(item);
                              }}
                            >
                              <div
                                className="video-container position-relative d-flex align-items-center justify-content-center"
                                style={{
                                  height: "100%",
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="col-12"
                                  src={
                                    item.image !== ""
                                      ? "https://bookmyqrcode.com/home_rental/" +
                                        item.image
                                      : "Assets/image/Group 168.png"
                                  }
                                  alt=""
                                />
                                <div className="play-button-container">
                                  <img
                                    className="img-fluid play-button"
                                    src="Assets/image/playbutton.png"
                                    alt=""
                                  />
                                </div>
                                <div className="col-md-3 col-5 p-0  position-absolute top-0 start-0 titelposter d-flex align-items-center justify-content-center">
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="p-0 m-0  mb-0 text-start p-2 videosTitleid "
                                  >
                                    {item.inventroyId}
                                  </p>
                                </div>
                                <div className="h6-container position-absolute bottom-0 start-0 titelpostersecond d-flex align-items-center justify-content-center">
                                  <div style={{ width: "100%" }}>
                                    {isSmallScreen ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1000 320"
                                      >
                                        <path
                                          fill="#F39C12"
                                          fillOpacity="1"
                                          d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                                        ></path>
                                        <text
                                          x="50%"
                                          y="30%"
                                          dominant-baseline="middle"
                                          text-anchor="middle"
                                          fill="white"
                                          fontSize="80"
                                        >
                                          <tspan dy="100">{item.title}</tspan>{" "}
                                          {/* Adjust dy to change the padding */}
                                        </text>{" "}
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1440 320"
                                      >
                                        <path
                                          fill="#F39C12"
                                          fillOpacity="1"
                                          d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                                        ></path>
                                        <text
                                          x="50%"
                                          y="30%"
                                          dominant-baseline="middle"
                                          text-anchor="middle"
                                          fill="white"
                                          fontSize="80"
                                        >
                                          <tspan dy="100">{item.title}</tspan>{" "}
                                          {/* Adjust dy to change the padding */}
                                        </text>{" "}
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="col-md-12 text-center d-flex justify-content-center p-4"
                style={{ width: "100%" }}
              >
                <div className="shimmer-container">
                  <dotlottie-player
                    className="lootie"
                    src="https://lottie.host/ce954cf3-ee28-4c87-8f12-73b69d1e2d95/5G93PDLU0Z.json"
                    style={{ maxwidth: "450px", height: "450px" }}
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="shimmer"></div>
                </div>
              </div>
            )}
            <div className="buttonss d-flex justify-content-center my-4 ">
              <button hidden={videoFilterData.length >= 0 ? true : false}>
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Content otherContent={otherContent} />
        <Footer />
      </div>
    </div>
  );
};

export default Product;
