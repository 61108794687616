import React, { memo } from "react";

const VarifiedBadge = memo(() => {
  return (
    <div className="pid position-absolute" style={{ display: "flex" }}>
      <div
        className="bg-light m-2 px-2 py-1 rounded d-flex align-items-center"
        style={{ color: "#3EB938" }}
      >
        {/* ID: {item.inventroyId} */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#3EB938"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          class="lucide lucide-shield-check"
        >
          <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
          <path d="m9 12 2 2 4-4" />
        </svg>
        Verified
      </div>
      <div
        className="bg-light m-2 px-2 py-1 rounded d-flex align-items-center"
      >
        owner's property
      </div>
    </div>
  );
});

export default VarifiedBadge;
