import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "./Header";
// import Category from "./Category";
import Form from "./Form";
import Footer from "./Footer/Footer";
import Content from "./Content";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { base_url } from "../constants/Service";
import HowItWorks from "./HowItWorks";
import WishListBtn from "./Buttons/WishListBtn";
import { wishlistItemCount } from "../Contexts/WishListContext";

const Homepage = () => {
  const { data } = useContext(wishlistItemCount);
  // Ref to footer element
  const footerRef = useRef(null);
  const pageTitle = "Home";
  const canonicalUrl = base_url;
  const [bottombutton, setbottombutton] = useState(true);
  const [buttonup, setbuttonup] = useState(false);
  const [otherContent, setOtherContent] = useState("");

  const Sliders = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "home_page_content",
        }
      );

      // console.log("dataHomepage--->", response);

      // Filter sliders with slider_type "home"
      setOtherContent(response.data.data[0]["description"]);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  useEffect(() => {
    // setTimeout(() => {
    //   Sliders();
    // }, 15000);
    window.onload = () => {
      Sliders();
    };
  }, []);

  // Function to handle scroll to footer
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });

    setbottombutton(false);

    setbuttonup(true);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);

    setbottombutton(true);

    setbuttonup(false);
  };

  const [selectedId, setSelectedId] = useState({});

  // Function to reset selectedId
  const resetSelectedId = () => {
    setSelectedId({});
  };

  return (
    <div className="w-100">
      <div
        className="position-fixed w-100 px-4 ms-auto bottom-0  vstack align-items-end mb-5 flex-column justify-content-end gap-4"
        style={{ zIndex: 100, pointerEvents: "none" }}
      >
        {bottombutton && (
          <button
            onClick={scrollToFooter}
            style={{
              background: "transparent",
              border: "none",
              outline: "none",
              pointerEvents: "all",
            }}
            className="cursor-pointer"
          >
            <svg
              width="2vw"
              style={{ minWidth: "30px" }}
              fill="red"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
        <a
          href="https://wa.me/919990025112"
          target="_blank"
          className="bg-white p-2 pe-4 rounded-pill d-flex align-items-center gap-2 shadow"
          style={{
            pointerEvents: "all",
          }}
        >
          <img
            src="Assets/image/whatsapp.png"
            alt=""
            style={{ height: "2vw", minHeight: "30px" }}
          />
          <span className="text-black">May I Help</span>
        </a>
        {buttonup && (
          <button
            onClick={scrollToTop}
            className="cursor-pointer"
            style={{
              transform: "rotate(180deg)",
              background: "transparent",
              border: "none",
              outline: "none",
              pointerEvents: "all",
            }}
          >
            <svg
              width="2vw"
              style={{ minWidth: "30px" }}
              fill="red"
              viewBox="0 0 16 16"
              aria-label="button"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg>
          </button>
        )}
        <div style={{ pointerEvents: "all", zIndex: 100 }}>
          <WishListBtn count={data.size} />
        </div>
      </div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="canonical" href="http://rentout.homes/video-listing" />
      </Helmet>
      <Header />
      <HowItWorks />
      {/* <div className="vstack w-100">
        <Category
          onCategrySelected={(id, name) => setSelectedId({ id, name })}
        />
      </div> */}
      <Form selectedId={selectedId} resetSelectedId={resetSelectedId} />
      <div ref={footerRef}>
        <Content otherContent={otherContent} />
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
