import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Shimmerui from "./Shimmerui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import InfiniteScroll from "react-infinite-scroller";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { postApiFunction } from "../api/Post_api";
import { API_BASE_URL } from "../constants/Service";
import { toastProperties } from "../helper/toastifyProperties";
import { FairPocketApi } from "../api/FairPockets_api";
import { BizMagnetApi } from "../api/BizMagnet_Api";
import FormModel from "./FormModel";
import VideoCard from "./VideoCard";
import FilterForm from "./FilterForm";
import { Filter } from "../Contexts/FilterContext";
import CustomLoader from "./Loader/CustomLoader";
import SectorMappingData from "../../src/assets/SectorMapping.json";

AOS.init();

const initialCategoryState = {
  value: "Select Category",
  label: "Select Category",
};

const Form = (props) => {
  const { videoFilterData, setVideoFilterData } = useContext(Filter);
  const [category, setCategory] = useState([]);
  const [categoryIds, setCategoryIds] = useState(initialCategoryState);
  const [selectedCityId, setSelectedCityId] = useState("4");
  // const [SelectedAreaId, setSelecteAreaId] = useState([]);
  const [SelectedAreaId, setSelecteAreaId] = useState("");
  const [value, setValue] = useState([]);
  const [minAmount, setMinAmount] = useState("500");
  const [maxAmount, setMaxAmount] = useState("500000");
  const stepAmount = "1000";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [videoData, setVideoData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [propsId, setPropsId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [howMany, setHowMany] = useState([]);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [workPlace, setWorkPlace] = useState("");
  const [residentType, setResidentType] = useState(null);
  const [nameOfCategories, setNameOfCategories] = useState([]);
  const [submitButtonColor, setSubmitButtonColor] = useState("#eb8500");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [defaultName, setDefaultName] = useState([]);
  const [defaultId, setDefaultId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PerDay, setPerDay] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  const getNearByAreas = useCallback(
    (areaID) => {
      return SectorMappingData.find((area) => area.areaId == areaID);
    },
    [SectorMappingData]
  );

  const dataFromInventory = (postData) => {
    console.log("param in datafrominventory: ", postData);
    axios
      .post("https://bookmyqrcode.com/home_rental/api/api.php", postData)
      .then((res) => {
        console.log("data from inventory:", res);
        if (res.data.data.length == 0) {
          console.log("called agian");
          const newPostData = { ...postData, areaId: "" };
          console.log(newPostData);
          dataFromInventory(newPostData);
        } else {
          setVideoFilterData(res.data.data);
          setHowMany(res.data.data.length);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error while fetching data for multiple areas", err);
        console.log("Error while fetching data for multiple areas", err);

        if (!err.response) {
          // Network error (e.g., no internet, server down)
          console.log("Network error: Please check your connection.");
        } else if (err.response.status === 404) {
          // Handle specific HTTP error (404 - Not Found)
          console.log("Data not found!");
        } else if (err.response.status >= 500) {
          // Handle server errors (e.g., 500 Internal Server Error)
          console.log("Server error, please try again later.");
        } else {
          // Handle other types of errors
          console.log(`An error occurred: ${err.response.status}`);
        }
        setVideoFilterData([]);
        setHowMany(0);
        setLoading(false);
      });
  };

  const fetchDataForMultipleAreas = async () => {
    setLoading(true);
    // const postData = {
    //   method: "getMappingInventroyData",
    //   categoryId: categoryIds, // categoryid
    //   cityId: selectedCityId, // city id
    //   areaIds: SelectedAreaId, // ['','',...area ids]
    //   // minBudget: 0,
    //   // // maxBudget: value[1],
    //   // maxBudget: 999999,
    // };

    let areasToSearch = "";
    let selectedAreaIdsArray = SelectedAreaId.split(",");
    // areasToSearch += SelectedAreaId;
    console.log(selectedAreaIdsArray);
    selectedAreaIdsArray.forEach((area) => {
      const dataFromGetNearByFunction = getNearByAreas(area);
      if (dataFromGetNearByFunction) {
        console.log(dataFromGetNearByFunction.nearByAreaIds);
        if (areasToSearch.length > 0) {
          areasToSearch += `,${dataFromGetNearByFunction.nearByAreaIds.join(
            ","
          )}`;
        } else {
          areasToSearch += dataFromGetNearByFunction.nearByAreaIds.join(",");
        }
      } else {
        if (areasToSearch.length > 0) {
          areasToSearch += `,${area}`;
        } else {
          areasToSearch += area;
        }
      }
    });
    // let selectedAreaIdsArray = SelectedAreaId.split(",");
    // let areasToSearch = "";
    // console.log(selectedAreaIdsArray);
    // selectedAreaIdsArray.forEach((area) => {
    //   const dataFromGetNearByFunction = getNearByAreas(area);
    //   if (dataFromGetNearByFunction) {
    //     console.log(dataFromGetNearByFunction.nearByAreaIds);

    //     dataFromGetNearByFunction.nearByAreaIds.forEach(
    //       (areaFromNearByAreaIds) => {
    //         areasToSearch += `,${areaFromNearByAreaIds}`;
    //       }
    //     );
    //   } else {
    //     if (areasToSearch.length > 0) {
    //       areasToSearch += `,${area}`;
    //     } else {
    //       areasToSearch += area;
    //     }
    //   }
    // });

    const postData = {
      method: "getDataInventory",
      categoryId: categoryIds.value,
      cityId: selectedCityId,
      areaId: areasToSearch,
      // minBudget: value[0],
      // minBudget: 0,
      // maxBudget: value[1],
      rent: `${value[0]},${value[1]}`
    };

    console.log(postData);

    // try {
    //   console.log("Inside try");
    //   const response = await axios.post(
    //     "https://bookmyqrcode.com/home_rental/api/api.php",
    //     {
    //       method: "getDataInventory",
    //       categoryId: "3",
    //       cityId: "4",
    //       areaId: "1",
    //     }
    //   );

    //   console.log("There is Data", response.data);
    // } catch (error) {
    //   console.error("Error fetching mapping data", error);
    // }

    console.log(postData);
    // axios
    //   .post("https://bookmyqrcode.com/home_rental/api/api.php", postData)
    //   .then((res) => {
    //     setVideoFilterData(res.data.data);
    //     setHowMany(res.data.data.length);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log("Error while fetching data for multiple areas", err);
    //     console.log("Error while fetching data for multiple areas", err);

    //     if (!err.response) {
    //       // Network error (e.g., no internet, server down)
    //       console.log("Network error: Please check your connection.");
    //     } else if (err.response.status === 404) {
    //       // Handle specific HTTP error (404 - Not Found)
    //       console.log("Data not found!");
    //     } else if (err.response.status >= 500) {
    //       // Handle server errors (e.g., 500 Internal Server Error)
    //       console.log("Server error, please try again later.");
    //     } else {
    //       // Handle other types of errors
    //       console.log(`An error occurred: ${err.response.status}`);
    //     }
    //     setVideoFilterData([]);
    //     setHowMany(0);
    //     setLoading(false);
    //   });
    dataFromInventory(postData);
  };

  useEffect(() => {
    axios
      .post("https://bookmyqrcode.com/home_rental/api/api.php", {
        method: "getFilterData",
      })
      .then((res) => {
        console.log("filter data from new api", res);
        setFilterOptions(res.data.data);
      })
      .catch((err) => console.log("error in fetching fiter data", err));
  }, []);

  const location = useLocation();
  const selectedId = location.state
    ? location.state.id || defaultId
    : defaultId;
  const selectedName = location.state
    ? location.state.name || defaultName
    : defaultName;
  const { categoryId } = useParams();

  const handleSelectChange = (selectedOption) => {
    // for changing category in filter form
    if (selectedOption) {
      setCategoryIds(selectedOption);
      setSelectedCategoryName(selectedOption.label);
    }
  };
  const handleCityChange = async (selectedOption) => {
    // for changing city in filter form
    setSelectedCityId(selectedOption.value);
  };
  const handleChange = (event, newValue) => {
    // for chaing min max value of the price range in the filter form
    setValue(newValue);
  };

  const fetchData = async (catID, offset = 0) => {
    console.log(catID, offset);
    try {
      let id =
        catID === undefined || catID === "12" || categoryId === undefined
          ? ""
          : catID;
      if (catID === "12") {
        id = "";
      }
      // console.log("catIDs", id);
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getDataInventory",
          categoryId: `${id}`,
          limit: "30",
          offset: `${offset}`,
        }
      );
      setVideoFilterData((prevData) => [...prevData, ...data?.data?.data]);
      setHowMany(data.data.inventroy_count);
      // console.log("totalinventory", data.data.inventroy_count);
      setVideoData(data.data.data);
      setNameOfCategories(selectedName);
      console.log(data);
      // console.log("Fetch Data Api Calling.....", catID);
      // console.log("datafetchvideos", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMore = async (offset) => {
    if (videoFilterData.length >= howMany) {
      setHasMore(false);
      return;
    }
    const newOffset = offset + 30;
    await fetchData("", newOffset);
  };

  const shareContent = () => {
    const message = `${window.location.href}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`);
  };

  // const handleAreaChange = (selectedOptions) => {
  //   setSelecteAreaId(selectedOptions.map((option) => option.value));
  // };

  const handleAreaChange = (selectedOptions) => {
    const areaIdString = selectedOptions
      .map((option) => option.value)
      .join(",");
    setSelecteAreaId(areaIdString);
  };

  const fetchcategorys = async () => {
    try {
      const result = await postApiFunction(
        "getData",
        "categories",
        "status",
        "1",
        "sort_order",
        "Desc",
        null,
        API_BASE_URL
      );
      if (result.status === "Success") {
        setCategory(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setDefaultValues = () => {
    console.log("No categories selected");
  };

  const SendData = async () => {
    if (loader) {
      return;
    }

    if (name.length === 0) {
      toast.error("Enter Name", toastProperties);
      return;
    }

    if (workPlace.length === 0) {
      toast.error("Enter Workplace", toastProperties);
      return;
    }

    if (residentType == null) {
      toast.error("Select Resident Type", toastProperties);
      return;
    }

    if (selectedData.brokerId === 0) {
      toast.error("Please Select Broker ID", toastProperties);
      return;
    }

    if (selectedData.inventroyId === 0) {
      toast.error("Please Select Inventory ID", toastProperties);
      return;
    }

    if (number.length < 1) {
      toast.error("Enter Number", toastProperties);
      return;
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", toastProperties);
      return;
    } else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", toastProperties);
      return;
    }

    setLoader(true);

    const formSubmitData = {
      name: name,
      brokerId: selectedData.brokerId,
      number: number,
      shifting_date: selectedDate,
      inventroyId: selectedData.inventroyId,
    };

    const FairPocketApiData = {
      name: name,
      mobile: number,
      email: "", // You may leave it as an empty string if not provided
      project: selectedData.category_name,
      source: "4",
      requirement: `<br><b>Inventory Id:</b> ${selectedData.inventroyId},<br>
                    <b>Title:</b> ${selectedData.title},<br>
                    <b>Broker Name:</b> ${selectedData.broker_name},<br>
                    <b>City Name:</b> ${selectedData.city_name},<br>
                    <b>Rent:</b> ${selectedData.rent},<br>
                    <b>Furniture Type:</b> ${selectedData.furniture_type},<br>
                    <b>Amenities:</b> ${selectedData.admin_remark}`,
    };

    const bizMagnetDataClient = {
      name: name,
      number: number,
      text: selectedData.broker_number,
      title: selectedData.title,
      data_to_send: "client",
    };

    const bizMagnetDataBroker = {
      name: selectedData.broker_name,
      number: selectedData.broker_number,
      text: number,
      title: selectedData.title,
      data_to_send: "broker",
    };

    try {
      const formDataSubmitApi = await postApiFunction(
        "setData",
        "enquiry",
        null,
        null,
        null,
        null,
        formSubmitData
      );

      await FairPocketApi(FairPocketApiData);

      await BizMagnetApi(bizMagnetDataClient);

      await BizMagnetApi(bizMagnetDataBroker);

      if (formDataSubmitApi?.status === "Success") {
        setSubmitButtonColor("#006622");
        setFormSubmitted(true);
        toast.success("Success", toastProperties);
        setName("");
        setNumber("");
        setSelectedDate(new Date());
        setTimeout(() => {
          setSubmitButtonColor("#eb8500");
          setFormSubmitted(false);
        }, 4000);
      } else {
        alert(false);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoader(false);
    }
  };

  const openModal = (data) => {
    setSelectedData(data);
    setModalOpen(true);

    const newcurrentUrl = new URL(window.location.href);
    newcurrentUrl.searchParams.set("id", data.inventroyId);

    window.history.pushState(null, "", newcurrentUrl.href);
  };

  const handleModalCloseByButton = () => {
    setSelectedData([]);
    window.history.back();
    setModalOpen(false);
  };

  window.onpopstate = () => {
    window.onpopstate = () => {};
    // window.history.back()
    setSelectedData([]);
    setModalOpen(false);
  };

  // All useEffect used
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 5000);

    if (categoryId) {
      // Assuming your URL format is consistent with "nameId" (e.g., "categoryName123")
      const categoryName = categoryId.slice(0, -1); // Extracting the name from the ID
      const id = categoryId.match(/\d+$/)[0];

      setDefaultName(categoryName);
      setDefaultId(id);

      // Log the category name and ID
      // console.log("Category Name:", categoryName);
      // console.log("Category IDs:", id);
    }

    // console.log("useEffect 1----->");
    if (categoryIds?.value?.length === 0) {
      setCategoryIds("1");
      // console.log("125", categoryIds);
    }
  }, []);

  useEffect(() => {
    fetchcategorys();
  }, [categoryId]);

  useEffect(() => {
    setPropsId(selectedId);
    setSelectedCategoryName("");
  }, [selectedId]);

  useEffect(() => {
    if (categoryIds?.value?.length > 0) {
      const selectedCategoriesData = category.filter((cat) =>
        categoryIds.value.includes(cat.categoryId)
      );

      if (selectedCategoriesData.length > 0) {
        const allMaxValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.max_amount)
        );
        const allMinValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.min_amount)
        );

        const maxAmount = Math.max(...allMaxValues);
        const minAmount = Math.min(...allMinValues);

        const finalMinAmount = minAmount || 5000;

        const finalMaxAmount = maxAmount || 500000;

        setValue([finalMinAmount, finalMaxAmount]);
        setMaxAmount(finalMaxAmount);
        setMinAmount(finalMinAmount);

        // console.log("selectedCategoriesData", selectedCategoriesData);
        // console.log("allMinValues", allMinValues);
        // console.log("allMaxValues", allMaxValues);
        // console.log("minAmount", finalMinAmount);
        // console.log("maxAmount", finalMaxAmount);
        // console.log("setValue", categoryIds);
      } else {
        setDefaultValues();
      }
    } else {
      setDefaultValues();
    }
  }, [categoryIds, category]);

  useEffect(() => {
    const currentUrl = window.location.href;
    // console.log("currentUrl", currentUrl);
    if (currentUrl.includes("id")) {
      setModalOpen(true);
      // console.log("yes it has id in url ");

      // Get the URL parameter 'id' from the current URL
      const idParam = new URLSearchParams(window.location.search).get("id");

      // Use regular expression to extract only the number from the parameter
      const idNumber = idParam ? parseInt(idParam.replace(/\D/g, "")) : null;

      // Check if a valid number is found
      if (!isNaN(idNumber)) {
        console.log("Number from URL:", idNumber);
      } else {
        console.log("No valid number found in URL");
      }

      const fetchVideoById = async () => {
        try {
          const data = await axios.post(
            "https://bookmyqrcode.com/home_rental/api/api.php",
            {
              method: "getDataInventory",
              column: "inventroyId",
              value: `${idNumber}`,
            }
          );
          // console.log("databyid", data.data.data[0]);

          setSelectedData(data.data.data[0]);
          // Handle fetched data as needed
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchVideoById(); // Call the function to fetch data
    }
  }, [window.location.href]);

  if (videoData.length === 0) {
    return <Shimmerui />;
  }
  return (
    <div className="d-flex justify-content-center overflow-hidden w-100">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader">
            <div className="shimmer-container">
              <iframe src="https://lottie.host/embed/d9f11e0b-3586-4c27-b585-686572b5343a/CSxWt1J3W5.json"></iframe>
              <div className="shimmer"></div>
            </div>
          </div>
        </div>
      )}
      {modalOpen && (
        <FormModel
          selectadedata={selectedData}
          handleModalCloseByButton={handleModalCloseByButton}
          PerDay={PerDay}
          videoFilterData={videoFilterData}
          openModal={openModal}
          setName={setName}
          name={name}
          number={number}
          setNumber={setNumber}
          SendData={SendData}
          workPlace={workPlace}
          setWorkPlace={setWorkPlace}
          residentType={residentType}
          setResidentType={setResidentType}
        />
      )}
      <ToastContainer style={{ fontSize: "14px", zIndex: "9999999999", border: "1px solid red" }} />
      <div className="w-100">
        <div
          className="d-flex flex-md-row flex-column gap-4 mx-auto"
          style={{ width: "fit-content" }}
        >
          <FilterForm
            handleSelectChange={handleSelectChange}
            selectedCityId={selectedCityId}
            SelectedAreaId={SelectedAreaId}
            handleCityChange={handleCityChange}
            value={value}
            categoryIds={categoryIds}
            handleChange={handleChange}
            stepAmount={stepAmount}
            minAmount={minAmount}
            maxAmount={maxAmount}
            handleFilter={fetchDataForMultipleAreas}
            handleAreaChange={handleAreaChange}
            filterOptions={filterOptions}
            initialCategoryState={initialCategoryState}
          />
          <div className="vstack gap-4">
            <h3 className="text-start Heading text-md-start text-center">
              {howMany} Results | For {categoryIds.label == "Select Category" ? "All Category" : categoryIds.label} Rent
            </h3>
            {videoFilterData.length !== 0 ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={
                  <div className="my-4">
                    <CustomLoader />
                  </div>
                }
              >
                <div className="card-grid">
                  {videoFilterData.map((item, index) => (
                    <VideoCard openModal={openModal} item={item} key={index} />
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div
                className="text-center d-flex justify-content-center p-4 mx-auto"
                style={{ width: "70vw", zIndex: -1 }}
              >
                <div className="shimmer-container">
                  <dotlottie-player
                    className="lootie"
                    src="https://lottie.host/ce954cf3-ee28-4c87-8f12-73b69d1e2d95/5G93PDLU0Z.json"
                    style={{ maxwidth: "450px", height: "450px" }}
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="shimmer"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="" id="mobilediv" style={{ position: "relative" }}>
          <div className="buttonss d-flex justify-content-center my-4">
            <button hidden={videoFilterData.length >= 0}>Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
