import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Component/Store";
import Homepage from "./Component/Homepage";
import Post from "./Component/Post";
import Loginpage from "./Component/Loginpage";
import Broker from "./Component/Broker";
import Reachus from "./Component/Reachus";
import Product from "./Component/Product";
import DummyProduct from "./Component/Dummy_Product";
import Blog from "./Component/Blog";
import BlogNewsPage from "./Component/BlogNewsPage";
import Aboutus from "./Component/Aboutus";
import { HelmetProvider } from "react-helmet-async";
import WishListContext from "./Contexts/WishListContext";
import CartPage from "./Component/Cart Page/CartPage";
import FilterContext from "./Contexts/FilterContext";
import TandC from "./Component/TermAndConditions/TandC"
import PrivacyPollicy  from "./Component/PrivacyPollicy/PrivacyPollicy.jsx"
import RefundPolicy from "./Component/RefundPolicy/RefundPolicy.jsx";
import BrokerOwnerDetails from "./Component/Broker Details Page/BrokerOwnerDetails.jsx";

function RedirectBasedOnUrl() {
  useEffect(() => {
    const { pathname } = window.location;

    if (
      pathname.includes("/video-detail") ||
      pathname.includes("/blog-detail") ||
      pathname.includes("/page/rentouthomes") ||
      pathname.includes("/video-inner-detail") ||
      pathname.includes("/page/about-us") ||
      pathname.includes("/page/privacy-policy") ||
      pathname.includes("/blog-category") ||
      pathname.includes("/page/terms-and-conditions") ||
      pathname.includes("/page") ||
      pathname.includes("/cgi-sys") ||
      pathname.includes("/logout") ||
      pathname.includes("/my-properties") ||
      pathname.includes("/blog-listing") ||
      pathname.includes("/video-listing") ||
      pathname.includes("/my-profile") ||
      pathname.includes("/forgot") ||
      pathname.includes("/+")
    ) {
      window.location.href = "/";
    }
  }, []);

  return null;
}

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <WishListContext>
            <FilterContext>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/checkout" element={<CartPage />} />
                <Route path="/post" element={<Post />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/Loginpage" element={<Loginpage />} />
                <Route path="/Broker" element={<Broker />} />
                <Route path="/Reachus" element={<Reachus />} />
                <Route path="/Blog" element={<Blog />} />
                <Route
                  path="/Blog/:blogTitle/:blogId"
                  element={<BlogNewsPage />}
                />
                <Route path="/:categoryId" element={<Product />} />
                <Route index element={<Homepage />} />
                <Route path="/tAndC" element={<TandC />}  />
                <Route path="/privacyPolicy" element={<PrivacyPollicy />}  />
                <Route path="/refundPolicy" element={<RefundPolicy />}  />
                <Route path="/brokerOwnerDetails" element={<BrokerOwnerDetails />}  />
              </Routes>
            </FilterContext>
          </WishListContext>
          <RedirectBasedOnUrl />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
