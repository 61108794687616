import { useCallback, useContext } from "react";
import { wishlistItemCount } from "../../Contexts/WishListContext";

function PaymentContent({ Details, onClick, id }) {
  const { data, setData } = useContext(wishlistItemCount);
  const removeThisItemFromData = useCallback(() => {
    const filteredData = Array.from(data).filter((item) => {
      // console.log(item.title.toLowerCase(), Details.toLowerCase());
      return item.title.toLowerCase() != Details.toLowerCase();
    });
    // console.log(filteredData);
    setData(new Set(filteredData));
  }, [Details, data, setData]);
  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderBottom: "2px dotted #00000033" }}
      >
        <p onClick={onClick} id={id} className="p-4" style={{flex: 1}}>{Details}</p>
        <button
          className="btn text-white bg-warning p-1 rounded d-flex justify-content-center align-items-center"
          onClick={removeThisItemFromData}
          style={{ height: "24px", width: "24px", minWidth: "24px" }}
        >
          -
        </button>
      </div>
    </div>
  );
}

export default PaymentContent;
