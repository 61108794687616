import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import Blog from "./Blog";
import { Helmet } from "react-helmet-async";
import { base_url } from "../constants/Service";

const Reachus = () => {
  const [concategory, consetcategory] = useState([]);
  const [conname, consetname] = useState("");
  const [sub, setsub] = useState("FLATMATE");
  const [email, setemail] = useState("");
  const [connumber, consetnumber] = useState("");
  const [mes, setmes] = useState("");
  const pageTitle = "Reach Us";
  const canonicalUrl = `${base_url}/Reachus`;

  useEffect(() => {
    fetchdatacon();
  }, []);

  const fetchdatacon = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const removeAllFromlist = data.data.data;
    removeAllFromlist.shift();
    consetcategory(removeAllFromlist);
    // console.log("data", data);
  };

  const Datacon = async () => {
    if (conname === "") {
      toast.error("Enter Your Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (connumber === "") {
      toast.error("Enter Your Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (connumber.length != "10") {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (email === "") {
      toast.error("Enter Your Email", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (sub === "") {
      toast.error("Enter Subject", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (mes === "") {
      toast.error("Enter Message", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",

        {
          method: "setData",
          table: "reach_us",
          data: {
            name: `${conname}`,
            mobileNumber: `${connumber}`,
            email: `${email}`,
            subject: `${sub}`,
            message: `${mes}`,
          },
        }
      );
      // console.log("data", data);

      $.ajax({
        url: "https://www.fairpockets.com/ApiIntegration/getdataapi",
        type: "POST",
        data: {
          apikey: "8880a70789ada62399a7b0d12a774197",
          name: `${conname}`,
          mobile: `${connumber}`,
          email: `${email}`, // You may leave it as an empty string if not provided

          source: "4",

          requirement: `
               <b>Category:</b> ${sub}
               <b>Message:</b> ${mes}
               `,
        },

        dataType: "json",
        success: function (data) {
          // Handle API response here
          // console.log("otherapi", data);
        },
        error: function (error) {
          // Handle errors here
          console.error("Error:->", error);
        },
      });

      consetname("");
      setsub("");
      setemail("");
      consetnumber("");
      setmes("");

      // Assuming data.data.status is checked correctly
      if (data.data.status === "Success") {
        toast.success("Success", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.success("Not Submitted", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      {/* <div style={{ background: "linear-gradient(193.19deg,rgba(234,226,255,.35) 3.09%,hsla(0,0%,100%,.35) 83.65%)" }} className='d-flex flex-column justify-content-center align-items-center  p-4' >
                <div className='text-center'>
                    <h1  style={{ fontWeight: "600" }} >About us</h1>
                    <h4 style={{ fontWeight: "600" }} className='p-4 pb-1'>Property owner / Channel Partners </h4>
                    <div className="d-flex justify-content-center align-items-center p-4">
                        <img src="Assets/image/phone.png" alt="" />
                        <h5 className='pb-0 mb-0 mx-3 '> <a style={{textDecoration:"none"}} className='text-black' href="tel:+91 9990025113">+91 9990025113 </a></h5>
                    </div>
                </div>
                <div className='text-center'>
                    <h4 style={{ fontWeight: "600" }} className='p-4 pb-1'>Other Queries</h4>

                    <div className="d-flex justify-content-center align-items-center p-4">
                        <img src="Assets/image/phone.png" alt="" />

                        <h5 className='pb-0 mb-0 mx-3 '><a  style={{textDecoration:"none"}}  className='text-black'   href="tel:+91 9990025115">+91 9990025115 </a></h5>

                    </div>
                </div>

            </div> */}

      <div className="container d-flex justify-content-between p-4">
        <div className="row col-md-12">
          <div className="col-md-8 d-flex flex-column p-4">
            <h4 className="fw-bold ">CONTACT US</h4>
            <div className="d-flex p-2 px-0 justify-content-evenly">
              <div className="row col-md-12">
                <div className="col-md-6  col-12 p-2">
                  <label for="inputZip" className="form-label">
                    {" "}
                    Name
                  </label>
                  <input
                    type="text"
                    value={conname}
                    onChange={(e) => {
                      consetname(e.target.value);
                    }}
                    className="form-control"
                  ></input>
                </div>
                <div className="col-md-6 p-2 col-12">
                  <label for="inputZip" className="form-label">
                    Number
                  </label>
                  <input
                    type="text"
                    value={connumber}
                    onChange={(n) => {
                      const limitedNumbers = n.target.value.slice(0, 10); // Limit to 10 characters
                      consetnumber(limitedNumbers);
                    }}
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>

            <div className="d-flex  px-0 justify-content-evenly">
              <div className="row col-md-12">
                <div className="col-md-6 col-12 p-2">
                  <label for="inputZip" className="form-label">
                    Subject
                  </label>
                  <select
                    type="text"
                    id="propertyType"
                    className="form-control px-2"
                    style={{ padding: "7px" }}
                    aria-label="Default select example"
                    value={sub} // Use categoryId here
                    placeholder="Select Category"
                    onChange={(e) => setsub(e.target.value)}
                  >
                    {concategory.map((s) => (
                      <option key={s.id} value={s.sub}>
                        {""}
                        {/* Use category ID as the value */}
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 p-2 col-12">
                  <label for="inputZip" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={(em) => {
                      setemail(em.target.value);
                    }}
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex p-4 px-0 justify-content-evenly">
              <div className="col-md-12  col-12">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    id="floatingTextarea2"
                    value={mes}
                    onChange={(m) => {
                      setmes(m.target.value);
                    }}
                    style={{ height: "250px" }}
                  ></textarea>
                  <label for="floatingTextarea2">Message</label>
                </div>
                <div className="buttonss mt-4">
                  <button onClick={Datacon}>Send Your Message</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <h4 className="fw-bold">Get in Touch</h4>
            <div className="p-4 px-0 mt-4">
              <h5 className="pb-0  text-black"> Address</h5>
              <h6 style={{ color: "#565151" }}>
                d floor, India Accelerator, BPTP Centra One, Baharampur Naya,
                Sector 61, Gurugram, Ghata, Haryana 122001
              </h6>
            </div>
            <div className="p-4 px-0 pt-0">
              <h5 className="pb-0 text-black"> Email</h5>
              <a
                href="mailto:Avnishyadav@rentout.homes"
                style={{ color: "#565151" }}
              >
                Avnishyadav@rentout.homes
              </a>
            </div>
            <div className="p-4 px-0 pt-0">
              <h5 className="pb-0 text-black pb-2"> Phone number</h5>
              <h6 style={{ color: "#565151" }}>
                PG, 1 RH, 1 BHK :{" "}
                <a
                  href="tel:+91 9990025112"
                  className="mx-2  "
                  style={{ color: "rgb(86, 81, 81)" }}
                >
                  +91 9990025112
                </a>
              </h6>
              <h6 style={{ color: "#565151" }}>
                Other Queries:{" "}
                <a
                  href="tel:+91 9899142130"
                  className="mx-2"
                  style={{ color: "rgb(86, 81, 81)" }}
                >
                  +91 9899142130
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <iframe
          className="col-md-12 p-0 col-12"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14036.402955150725!2d77.0931791!3d28.416217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d21af78a0977d%3A0x9dee2275ac8bee84!2sRENTOUT%20HOMES!5e0!3m2!1sen!2sin!4v1709621601313!5m2!1sen!2sin"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <ToastContainer style={{ fontSize: "14px", zIndex: "9999999" }} />

      <Footer />
    </div>
  );
};

export default Reachus;
