import React, { createContext, memo, useCallback, useState } from "react";

export const wishlistItemCount = createContext();

const WishListContext = memo(({ children }) => {
  const [data, setData] = useState(new Set());
  const addDataToWishList = (data) => {
    setData((prevData) => {
      const newData = new Set(prevData);
      newData.add(data);
      return newData;
    });
  };
  const removeFromCart = (data) => {
    setData((prevState) => {
      const oldData = Array.from(prevState);
      const newData = oldData.filter(
        (item) => item.inventroyId != data.inventroyId
      );
      return new Set(newData);
    });
  };
  return (
    <wishlistItemCount.Provider
      value={{ data, setData, addDataToWishList, removeFromCart }}
    >
      {children}
    </wishlistItemCount.Provider>
  );
});

export default WishListContext;
