import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import WishListBtn from "./Buttons/WishListBtn";
import { wishlistItemCount } from "../Contexts/WishListContext";
import {
  minNumberOfOrdersAllowedToGetOwnerDetails,
  pricesForRentalDetails,
} from "../constants/pricingDetails";
import VarifiedBadge from "./VarifiedBadge";

const categorieNames = [
  "PG",
  null,
  "1 RK",
  "1 BHK",
  "2 BHK",
  "3 BHK",
  "4 BHK",
  "5 BHK",
  "Market Place",
  "Short Stay",
  "Adventure & Group Trips",
];

const FormModel = memo(
  ({
    selectadedata,
    handleModalCloseByButton,
    PerDay, //tells whether the rent is perday based
    videoFilterData = [],
    openModal,
  }) => {
    const [isPresent, setIsPresent] = useState(false);
    const [postIdList, setPostIdList] = useState([]);
    const { data, addDataToWishList, removeFromCart } =
      useContext(wishlistItemCount);
    const getIdFromUrl = () => {
      const currentUrl = new URL(window.location.href);
      return currentUrl.searchParams.get("id");
    };

    const goToNextPost = useCallback(() => {
      const id = getIdFromUrl();
      const idIndex = postIdList.indexOf(id);
      if (idIndex < postIdList.length - 1) {
        // console.log(idIndex, "again called")
        openModal(videoFilterData[idIndex + 1]);
      }
    }, [postIdList, videoFilterData]);

    const goToPreviousPost = useCallback(() => {
      const id = getIdFromUrl();
      const idIndex = postIdList.indexOf(id);
      if (idIndex > 0) {
        openModal(videoFilterData[idIndex - 1]);
      }
    }, [postIdList, videoFilterData]);
    const currentId = getIdFromUrl();

    useEffect(() => {
      const found = Array.from(data).find((element) => {
        // console.log(element.inventroyId);
        return element.inventroyId == currentId;
      });
      if (!!found) {
        setIsPresent(true);
      } else {
        setIsPresent(false);
      }
    }, [currentId]);
    const addItemToWishList = useCallback(() => {
      addDataToWishList(selectadedata);
      setIsPresent(true);
    }, [selectadedata]);
    const removeFromWishList = useCallback(() => {
      removeFromCart(selectadedata);
      setIsPresent(false);
    }, [selectadedata]);
    useEffect(() => {
      const postIdList = videoFilterData.map((item) => item.inventroyId);
      setPostIdList(postIdList);
    }, []);
    return (
      <div
        className="position-fixed bg-dark bg-opacity-50 d-flex justify-content-center gap-0"
        style={{
          inset: 0,
          zIndex: 1000000,
          overflowY: "auto",
        }}
      >
        <button
          className="p-2 rounded-circle border-0 formModelArrows align-self-center"
          onClick={goToPreviousPost}
          style={{ left: 16 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-chevron-left"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
        </button>
        <div
          className="d-flex justify-content-center overflow-y-auto gap-4 px-4 position-relative z-0"
          id="formModel"
          style={{
            inset: 0,
            overflowY: "auto",
            padding: "2.5rem",
            paddingBottom: "5rem",
          }}
        >
          <div
            className="position-fixed"
            style={{ zIndex: 1010, bottom: "3rem", right: 24 }}
          >
            <WishListBtn count={data.size} />
          </div>
          <Helmet>
            <title>{selectadedata.title}</title>
            <meta property="og:title" content={selectadedata.title} />
            <meta
              property="og:image"
              content="https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&w=600"
            />
          </Helmet>
          <div>
            <div className="position-relative d-flex gap-4 overflow-y-auto rounded-4">
              <div
                onClick={handleModalCloseByButton}
                className="position-absolute cursor-pointer"
                style={{ top: "1rem", right: "1rem" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  color="#A93226"
                  fill="currentColor"
                  className="bi bi-x-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
              </div>
              <div className="bg-white rounded-4">
                {data.size < minNumberOfOrdersAllowedToGetOwnerDetails && (
                  <div
                    className="text-md-center p-2 h3 ps-4 w-100"
                    style={{ width: "80%" }}
                  >
                    Add{" "}
                    <span className="text-danger">
                      {minNumberOfOrdersAllowedToGetOwnerDetails - data.size}{" "}
                      more
                    </span>{" "}
                    to get Owner/Broker's Details
                  </div>
                )}
                <div
                  className="d-flex flex-md-row flex-column gap-4 p-4 bg-light align-items-md-center align-items-stretch md-pt-0 pt-5 rounded-4"
                  style={{ maxWidth: "90vw" }}
                >
                  <div
                    className="video rounded-3 position-relative shadow p-3 flex-grow-1 md:h-100 md-w-50"
                    style={{ aspectRatio: 0.55, flex: 1 }}
                  >
                    <VarifiedBadge />
                    <iframe
                      src={selectadedata.video_link}
                      id="videoIframe"
                      title="video"
                      className="w-100 h-100"
                      style={{ objectFit: "cover" }}
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div
                    className="right flex-grow-1 d-flex flex-column gap-2 md-w-50"
                    style={{ flex: 1 }}
                  >
                    {/* <div className="interested fw-bold d-flex gap-2 order-md-first order-last">
                      <span className="text-warning">
                        {selectadedata.enquiryCount}
                      </span>
                      Interested
                    </div> */}
                    <div className="d-flex gap-4 align-items-center justify-content-between order-md-first order-last">
                      <h4 className="fw-bold">{selectadedata.title}</h4>
                      <h6 className="uniqueId">{selectadedata.inventroyId}</h6>
                    </div>
                    <div className="d-flex gap-2 align-items-stretch order-md-first order-last">
                      <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50 text-center">
                        <h6 className="fw-bold">Rent</h6>
                        <p>
                          {selectadedata.rent}
                          {PerDay && (
                            <strong className="mx-1">/ Per Day</strong>
                          )}
                        </p>
                      </div>
                      <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50 text-center">
                        <h6 className="fw-bold">Category Type</h6>
                        <p>{categorieNames[selectadedata.categoryId - 1]}</p>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-stretch order-md-first order-last">
                      <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50 text-center">
                        <h6 className="fw-bold">Area</h6>
                        <p>{selectadedata.area_name}</p>
                      </div>
                      <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50 text-center">
                        <h6 className="fw-bold">Furniture Type</h6>
                        <p>{selectadedata.furniture_type}</p>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-stretch order-md-first order-last text-center">
                      <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
                        <h6 className="fw-bold">City</h6>
                        <p>{selectadedata.city_name}</p>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center rounded-4 border border-black p-2 w-50 text-center">
                        <h6 className="fw-bold">Channel Partner</h6>
                        <p>{selectadedata.broker_name}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 text-center">
                      <h6 className="fw-bold">Amenities</h6>
                      <p className="text-center">
                        {selectadedata.admin_remark}
                      </p>
                    </div>
                    <button
                      style={{
                        padding: "0.8rem",
                        backgroundColor: !isPresent ? "#FF0000" : "transparent",
                        border: "2px solid #FF0000",
                        color: isPresent ? "#FF0000" : "white",
                      }}
                      // disabled={formSubmitted}
                      className="w-100 order-md-last order-first rounded-2"
                      onClick={
                        isPresent ? removeFromWishList : addItemToWishList
                      }
                    >
                      {isPresent ? (
                        "Remove From Cart"
                      ) : (
                        <>
                          To Contact owner{" "}
                          <span style={{ fontWeight: 950 }}>- Add to cart</span>
                        </>
                      )}
                    </button>
                    <h6
                      className="text-center fw-bold p-2 rounded"
                      style={{ backgroundColor: "rgba(235, 250, 234, 1)" }}
                    >
                      Get Owner’s Details only at &nbsp;
                      <span
                        className="h3 text-success"
                        style={{ whiteSpace: "nowrap", fontWeight: 950 }}
                      >
                        Rs. {pricesForRentalDetails[selectadedata.categoryId]}/-
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block" style={{ height: "6.5rem" }}>{/*used for bottom margin dont remove */}</div>
          </div>
        </div>
        <button
          className="p-2 rounded-circle border-0 formModelArrows align-self-center"
          onClick={goToNextPost}
          style={{ right: 16 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-chevron-right"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </button>
      </div>
    );
  }
);

export default FormModel;
