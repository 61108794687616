import React, { memo } from "react";
import OtherCourseCard from "./OtherCourseCard";

const Suggestions = memo(({otherRentals, toggleModel}) => {
  return (
    <div className="d-flex flex-column gap-2" style={{ height: 350 }}>
      <p className="h4 fw-bold text-dark text-start">Suggestions:</p>{" "}
      <div
        className="d-flex gap-3 pt-4 pb-2 px-2"
        style={{ flex: 1, overflowX: "auto", aspectRatio: 1 }}
      >
        {otherRentals.map((item, index) => (
          <OtherCourseCard
            id={`${index} otherRentals`}
            key={item.inventroyId}
            item={item}
            onClick={toggleModel}
          />
        ))}
      </div>
    </div>
  );
});

export default Suggestions;
