import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import { wishlistItemCount } from "../Contexts/WishListContext";
import { postApiFunction } from "../api/Post_api";
import { FairPocketApi } from "../api/FairPockets_api";
import { BizMagnetApi } from "../api/BizMagnet_Api";
import { toastProperties } from "../helper/toastifyProperties";
import { pricesForRentalDetails } from "../constants/pricingDetails";


const categorieNames = [
  "PG",
  null,
  "1 RK",
  "1 BHK",
  "2 BHK",
  "3 BHK",
  "4 BHK",
  "5 BHK",
  "Market Place",
  "Short Stay",
  "Adventure & Group Trips",
];

const CartModel = memo(({ item, itemCategory, setOpenModel }) => {
  const { data, addDataToWishList, removeFromCart } =
    useContext(wishlistItemCount);
  const [itemIndex, setItemIndex] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [workPlace, setWorkPlace] = useState("");
  const [residentType, setResidentType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isPresent, setIsPresent] = useState(false);
  const addItemToWishList = useCallback(() => {
    addDataToWishList(videoList[itemIndex]);
    setIsPresent(true);
  }, [videoList, itemIndex]);

  const removeItemFromWishList = useCallback(() => {
    removeFromCart(videoList[itemIndex]);
    setIsPresent(false);
  }, [videoList, itemIndex]);

  const changeItemIndex = useCallback(
    (e) => {
      const id = e.currentTarget.id;
      if (id == "prev") {
        setItemIndex((prevState) => {
          // console.log(prevState, typeof prevState);
          return Number(prevState > 0 ? prevState - 1 : prevState);
        });
      } else {
        setItemIndex((prevState) => {
          // console.log(prevState, typeof prevState);
          return Number(
            prevState < videoList.length - 1 ? prevState + 1 : prevState
          );
        });
      }
    },
    [videoList]
  );

  console.log(videoList[itemIndex]);

  const SendData = async () => {
    if (name.length === 0) {
      toast.error("Enter Name", toastProperties);
      return;
    }
    if (workPlace.length === 0) {
      toast.error("Enter Workplace", toastProperties);
      return;
    }
    if (residentType == null) {
      toast.error("Select Resident Type", toastProperties);
      return;
    }
    if (videoList[itemIndex].brokerId === 0) {
      toast.error("Please Select Broker ID", toastProperties);
      return;
    }

    if (videoList[itemIndex].inventroyId === 0) {
      toast.error("Please Select Inventory ID", toastProperties);
      return;
    }

    if (number.length < 1) {
      toast.error("Enter Number", toastProperties);
      return;
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", toastProperties);
      return;
    } else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", toastProperties);
      return;
    }
    const formSubmitData = {
      name: name,
      brokerId: videoList[itemIndex].brokerId,
      number: number,
      shifting_date: selectedDate,
      inventroyId: videoList[itemIndex].inventroyId,
    };

    const FairPocketApiData = {
      name: name,
      mobile: number,
      workPlace: workPlace,
      residentType: residentType,
      email: "", // You may leave it as an empty string if not provided
      project: videoList[itemIndex].category_name,
      source: "4",
      requirement: `<br><b>Inventory Id:</b> ${videoList[itemIndex].inventroyId},<br>
<b>Title:</b> ${videoList[itemIndex].title},<br>
<b>Broker Name:</b> ${videoList[itemIndex].broker_name},<br>
<b>City Name:</b> ${videoList[itemIndex].city_name},<br>
<b>Rent:</b> ${videoList[itemIndex].rent},<br>
<b>Furniture Type:</b> ${videoList[itemIndex].furniture_type},<br>
<b>Amenities:</b> ${videoList[itemIndex].admin_remark}`,
    };

    const bizMagnetDataClient = {
      name: name,
      number: number,
      workPlace: workPlace,
      residentType: residentType,
      text: videoList[itemIndex].broker_number,
      title: videoList[itemIndex].title,
      data_to_send: "client",
    };

    const bizMagnetDataBroker = {
      name: videoList[itemIndex].broker_name,
      number: videoList[itemIndex].broker_number,
      text: number,
      title: videoList[itemIndex].title,
      data_to_send: "broker",
    };

    try {
      const formDataSubmitApi = await postApiFunction(
        "setData",
        "enquiry",
        null,
        null,
        null,
        null,
        formSubmitData
      );

      await FairPocketApi(FairPocketApiData);

      await BizMagnetApi(bizMagnetDataClient);

      await BizMagnetApi(bizMagnetDataBroker);

      if (formDataSubmitApi?.status === "Success") {
        toast.success("Success", toastProperties);
        setName("");
        setNumber("");
        setWorkPlace("");
        setResidentType(null);
        setSelectedDate(new Date());
      } else {
        alert(false);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  useEffect(() => {
    setItemIndex(item);
    setVideoList(Array.from(itemCategory));
  }, [item, itemCategory]);
  useEffect(() => {
    const found = Array.from(data).find((element) => {
      // console.log(element.inventroyId);
      return element.inventroyId == videoList[itemIndex]?.inventroyId;
    });
    if (!!found) {
      setIsPresent(true);
    } else {
      setIsPresent(false);
    }
  }, [videoList, itemIndex]);
  return videoList.length != 0 ? (
    <div
      className="position-fixed bg-dark bg-opacity-50"
      style={{ inset: 0, zIndex: 100, overflowY: "auto" }}
    >
      <button
        className="rounded-circle p-1 ms-auto d-block m-4 bg-danger"
        onClick={() => setOpenModel((prevState) => !prevState)}
        style={{ border: "none", outline: "none" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="4vw"
          style={{ minWidth: 24, maxWidth: 50 }}
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </button>
      <div className="d-flex align-items-center gap-4 justify-content-center">
        <button
          className="prev rounded-circle p-1 bg-danger"
          id="prev"
          onClick={changeItemIndex}
          style={{ border: "none", outline: "none" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4vw"
            style={{ minWidth: 24, maxWidth: 50 }}
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-chevron-left"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
        </button>
        <div className="model" style={{ aspectRatio: 1 }}>
          <div className="d-flex flex-column gap-4 align-items-center justify-content-start overflow-y-auto">
            <div className="bg-white  rounded-4">
              {data.size < 3 && (
                <h5 className="text-center p-2">
                  Add <span className="text-danger">{3 - data.size} more</span>{" "}
                  to get Owner/Broker's Details
                </h5>
              )}
              <div
                className="d-flex flex-md-row flex-column gap-4 p-4 bg-light align-items-md-center align-items-stretch md-pt-0 pt-sm-5"
                style={{ maxWidth: "90vw" }}
              >
                <div
                  className="video rounded-3 position-relative shadow p-3 flex-grow-1 md:h-100 md-w-50"
                  style={{ aspectRatio: 0.55, flex: 1 }}
                >
                  <iframe
                    src={videoList[itemIndex].video_link}
                    id="videoIframe"
                    title="video"
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                    allowFullScreen
                  ></iframe>
                </div>
                <div
                  className="right flex-grow-1 d-flex flex-column gap-4 md-w-50"
                  style={{ flex: 1 }}
                >
                  <div className="interested fw-bold d-flex gap-2 order-md-first order-last">
                    <span className="text-warning">
                      {videoList[itemIndex].enquiryCount}
                    </span>
                    Interested
                  </div>
                  <div className="d-flex gap-4 align-items-center justify-content-between order-md-first order-last">
                    <h4 className="fw-bold">{videoList[itemIndex].title}</h4>
                    <h6 className="uniqueId">
                      {videoList[itemIndex].inventroyId}
                    </h6>
                  </div>
                  <div className="d-flex gap-4 align-items-stretch order-md-first order-last">
                    <div className="d-flex flex-column justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
                      <h6 className="fw-bold">Channel Partner</h6>
                      <p>{videoList[itemIndex].broker_name}</p>
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
                      <h6 className="fw-bold">Category Type</h6>
                      <p>
                        {categorieNames[videoList[itemIndex].categoryId - 1]}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-stretch order-md-first order-last">
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
                      <h6 className="fw-bold">City</h6>
                      <p>{videoList[itemIndex].city_name}</p>
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
                      <h6 className="fw-bold">Area</h6>
                      <p>{videoList[itemIndex].area_name}</p>
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-stretch order-md-first order-last">
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
                      <h6 className="fw-bold">Rent</h6>
                      <p>{videoList[itemIndex].rent}</p>
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
                      <h6 className="fw-bold">Furniture Type</h6>
                      <p>{videoList[itemIndex].furniture_type}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2">
                    <h6 className="fw-bold">Aminities</h6>
                    <p className="text-center">
                      {videoList[itemIndex].admin_remark}
                    </p>
                  </div>
                  {/* {videoList[itemIndex].categoryId != 1 &&
                    videoList[itemIndex].categoryId != 4 && (
                      <div
                        className="py-2 row row-cols-2 gx-4 gy-4 align-items-stretch justify-content-stretch"
                        style={{ maxWidth: 500 }}
                      >
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            className="border-2 border-warning p-2 rounded-4  w-100 h-100"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ outline: "none" }}
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            placeholder="Enter Your Number"
                            className="border-2 border-warning p-2 rounded-4  w-100 h-100"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            style={{ outline: "none" }}
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Your Work Place"
                            className="border-2 border-warning p-2 rounded-4  w-100 h-100"
                            value={workPlace}
                            onChange={(e) => setWorkPlace(e.target.value)}
                            style={{ outline: "none" }}
                            required
                          />
                        </div>
                        <div>
                          <Selectoptions
                            placeholder="Select Resident Type"
                            options={ResidentTypes}
                            residentType={residentType}
                            setResidentType={setResidentType}
                          />
                        </div>
                      </div>
                    )} */}
                  <button
                    style={{
                      padding: "0.8rem",
                      backgroundColor: !isPresent ? "#E7A03C" : "transparent",
                      border: "2px solid #E7A03C",
                      color: isPresent ? "#E7A03C" : "white",
                    }}
                    // disabled={formSubmitted}
                    className="w-100 order-md-last order-first rounded-2"
                    onClick={isPresent ? removeItemFromWishList : addItemToWishList}
                  >
                    {isPresent ? "Remove From Cart" : "Add to Cart"}
                  </button>
                  <h6 className="text-center fw-bold">
                    Get Owner’s Details only at &nbsp;
                    <span className="h3 text-success">
                      Rs.{" "}
                      {pricesForRentalDetails[videoList[itemIndex].categoryId]}
                      /-
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          id="next"
          className="next bg-danger rounded-circle p-1"
          onClick={changeItemIndex}
          style={{ border: "none", outline: "none" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4vw"
            style={{ minWidth: 24, maxWidth: 50 }}
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-chevron-right"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default CartModel;
