import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import PrimaryBtn from "./Buttons/PrimaryBtn";
import { debounce } from "../functions/helperFunction";
import SavingPopUp from "./Savings Pop-UP/SavingPopUp";
import { savingsByType, propertyTypes } from "../constants/pricingDetails";
import { getKeyByValueFromObj } from "../functions/helperFunction";

const getTypeOfProduct = getKeyByValueFromObj(propertyTypes);

const FilterForm = memo(
  ({
    filterOptions,
    selectedCityId,
    handleCityChange,
    handleSelectChange,
    SelectedAreaId,
    categoryIds,
    initialCategoryState,
    value,
    handleChange,
    stepAmount,
    minAmount,
    maxAmount,
    handleFilter,
    handleAreaChange,
  }) => {
    const [showSavingsPopUP, setShowSavingsPopUP] = useState(false);
    const [currentWidowSize, setCurrentWindowSize] = useState(
      window.innerWidth
    );
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [setOfCategoriesToDisplay, setSetOfCategoriesToDisplay] = useState(
      []
    );

    const toggleSavingsPopUp = useCallback(() => {
      setShowSavingsPopUP((prevState) => !prevState);
    }, [setShowSavingsPopUP]);

    const toggleFilterMenu = useCallback(() => {
      setShowFilterMenu((prevState) => !prevState);
    }, [setShowFilterMenu]);

    const debouncedResizeHandler = useMemo(
      () =>
        debounce((e) => {
          setCurrentWindowSize(e.target.innerWidth);
        }, 700),
      [debounce, setCurrentWindowSize]
    );

    useEffect(() => {
      window.addEventListener("resize", debouncedResizeHandler);
      return () => {
        window.removeEventListener("resize", debouncedResizeHandler);
      };
    }, [setCurrentWindowSize, window.innerWidth]);

    const addCategoriesToBeDisplayed = useCallback((p_category) => {
      setSetOfCategoriesToDisplay((prevState) => {
        const categoryAlreadyExist = prevState.find(
          (category) => category.categoryId == p_category.categoryId
        );
        if (!categoryAlreadyExist) {
          return [...prevState, p_category];
        }
        return prevState;
      });
    }, []);

    useEffect(() => {
      setSetOfCategoriesToDisplay([]);
      SelectedAreaId.split(",").forEach((areaId) => {
        filterOptions[selectedCityId]?.areas
          .find((area) => area.areaId === areaId)
          ?.categories.forEach((category) =>
            addCategoriesToBeDisplayed(category)
          );
      });
      handleSelectChange(initialCategoryState);
    }, [SelectedAreaId, initialCategoryState]);
    console.log(categoryIds);
    return (
      // <div
      //   className="form mx-auto position-sticky top-0"
      //   style={{ width: 350, maxWidth: "100vw", zIndex: 10 }}
      // >
      //   <div>
      //     <div className="container px-2 maindiv ">
      //       <label htmlFor="exampleFormControlInput1" className="form-label">
      //         City
      //       </label>
      //       <div className="City">
      //         <Select
      //           className=""
      //           id=""
      //           placeholder="Select City"
      //           options={Object.keys(filterOptions).map((City) => ({
      //             label: filterOptions[City].cityName,
      //             value: City,
      //           }))}
      //           onChange={handleCityChange}
      //         />
      //       </div>
      //     </div>
      //     <div className="container px-2 maindiv mt-1 ">
      //       <label
      //         htmlFor="exampleFormControlInput1"
      //         className="form-label mb-1"
      //       >
      //         Area
      //       </label>
      //       <div className="Areaselectarea">
      //         <Select
      //           className=""
      //           isMulti
      //           placeholder="Select Area"
      //           noOptionsMessage={() => "Please select any City to see Areas."}
      //           options={filterOptions[selectedCityId]?.areas.map(
      //             (areadata) => ({
      //               label: areadata.name,
      //               value: areadata.areaId,
      //             })
      //           )}
      //           onChange={(selectedOptions) =>
      //             handleAreaChange(selectedOptions)
      //           }
      //         />
      //       </div>
      //     </div>
      //   </div>
      //   <div id="">
      //     <div className="container px-2 maindiv">
      //       <label htmlFor="exampleFormControlInput1" className="form-label">
      //         Product
      //       </label>
      //       <div className="  cityp ">
      //         <div className="">
      //           <Select
      //             className=""
      //             id=""
      //             isMulti={false} // Set to false for single selection
      //             placeholder="Select Rental Type"
      //             options={setOfCategoriesToDisplay.map((category) => ({
      //               label: category.categoryName,
      //               value: category.categoryId,
      //             }))}
      //             noOptionsMessage={() =>
      //               "Please select any Area to see categories."
      //             }
      //             onChange={handleSelectChange}
      //             value={{
      //               label: categoryIds.label,
      //               value: categoryIds.value,
      //             }}
      //           />
      //         </div>
      //       </div>
      //     </div>
      //     {categoryIds.label != "Select Category" && (
      //       <div className=" col-md-12 px-2 maindiv towmaindiv mt-2">
      //         <label
      //           htmlFor="exampleFormControlInput1"
      //           className="form-label mb-1"
      //         >
      //           Budget
      //         </label>
      //         <Box className="container ">
      //           <Slider
      //             className="roodt"
      //             getAriaLabel={() => "Temperature range"}
      //             defaultValue={30}
      //             valueLabelDisplay="auto"
      //             value={value}
      //             Slider
      //             onChange={handleChange}
      //             step={stepAmount}
      //             marks
      //             min={parseFloat(minAmount)}
      //             max={parseFloat(maxAmount)}
      //           />
      //         </Box>
      //         <div
      //           className="container-fluid d-flex justify-content-between maxheight"
      //           style={{ maxHeight: "15px" }}
      //         >
      //           <div>
      //             <p>₹ {value[0]}</p>
      //           </div>
      //           <div>
      //             <p>₹ {value[1]}</p>
      //           </div>{" "}
      //         </div>
      //       </div>
      //     )}
      //   </div>
      //   <div className="d-flex justify-content-center align-items-center my-1">
      //     <div className="buttonssdif d-flex">
      //       <button onClick={handleFilter}>Search</button>
      //     </div>{" "}
      //   </div>
      // </div>
      <>
        {showSavingsPopUP && (
          <SavingPopUp
            type={categoryIds?.label}
            savings={savingsByType[Number(categoryIds?.value)]}
            closePopUp={toggleSavingsPopUp}
          />
        )}
        <div
          className="d-flex flex-column gap-3 pt-2 pt-md-0"
          style={{ height: "fit-content", zIndex: 10 }}
        >
          {/* <div
            className="d-md-none d-flex gap-3 align-items-center px-4 py-3"
            style={{ boxShadow: "0 3px 13.5px 0 rgba(0, 0, 0, 0.15)" }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#E7A03C"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-sliders-horizontal"
              >
                <line x1="21" x2="14" y1="4" y2="4" />
                <line x1="10" x2="3" y1="4" y2="4" />
                <line x1="21" x2="12" y1="12" y2="12" />
                <line x1="8" x2="3" y1="12" y2="12" />
                <line x1="21" x2="16" y1="20" y2="20" />
                <line x1="12" x2="3" y1="20" y2="20" />
                <line x1="14" x2="14" y1="2" y2="6" />
                <line x1="8" x2="8" y1="10" y2="14" />
                <line x1="16" x2="16" y1="18" y2="22" />
              </svg>
            </div>
            <div className="fw-semibold" style={{ fontSize: 20.58 }}>
              Filter
            </div>
          </div> */}
          <div
            className="fw-semibold"
            style={{ fontSize: 35 }}
          >
            Filter
          </div>
          <div
            className="p-4 fw-semibold d-flex flex-column gap-3 mx-auto bg-white start-50 filterFormMenu"
            style={{
              border: "1px solid rgba(37, 37, 37, 0.32)",
              borderRadius: 14,
              height: "fit-content",
              fontSize: 20,
              top: "120%",
            }}
          >
            <div>Property Types</div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: 18 }}
            >
              <div>City</div>
              <div className="text-danger">Gurgaon</div>
            </div>
            <div className="d-flex gap-1 flex-column fs-6 fw-normal">
              <div className="fw-normal" style={{ fontSize: 18 }}>
                Area
              </div>
              <Select
                className=""
                isMulti
                placeholder="Select Area"
                noOptionsMessage={() => "Please select any City to see Areas."}
                options={filterOptions[selectedCityId]?.areas.map(
                  (areadata) => ({
                    label: areadata.name,
                    value: areadata.areaId,
                  })
                )}
                onChange={(selectedOptions) =>
                  handleAreaChange(selectedOptions)
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      state.isFocused || state.menuIsOpen ? "#E7A03C" : "grey",
                    boxShadow:
                      state.isFocused || state.menuIsOpen
                        ? `0 0 0 1px #E7A03C`
                        : baseStyles.boxShadow,
                    borderRadius: 8,
                    "&:hover": { borderColor: "#E7A03C" },
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FFDDAE",
                    primary: "#E7A03C",
                  },
                })}
              />
            </div>
            <div className="d-flex gap-1 flex-column fs-6 fw-normal">
              <div className="fw-normal" style={{ fontSize: 18 }}>
                Product
              </div>
              <Select
                className=""
                id=""
                isMulti={false} // Set to false for single selection
                placeholder="Select Rental Type"
                options={setOfCategoriesToDisplay.map((category) => ({
                  label: category.categoryName,
                  value: category.categoryId,
                }))}
                noOptionsMessage={() =>
                  "Please select any Area to see categories."
                }
                onChange={handleSelectChange}
                value={{
                  label: categoryIds.label,
                  value: categoryIds.value,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      state.isFocused || state.menuIsOpen ? "#E7A03C" : "grey",
                    boxShadow:
                      state.isFocused || state.menuIsOpen
                        ? `0 0 0 1px #E7A03C`
                        : baseStyles.boxShadow,
                    borderRadius: 8,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FFDDAE",
                    primary: "#E7A03C",
                  },
                })}
              />
            </div>
            {categoryIds.label != "Select Category" && (
              <div className="d-flex gap-1 flex-column fs-6 fw-normal">
                <div className="fw-normal" style={{ fontSize: 18 }}>
                  Price Range
                </div>
                <div>
                  <Box className="container">
                    <Slider
                      sx={{
                        color: "white",
                        height: 6,
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#E7A03C",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "grey",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#E7A03C",
                          borderColor: "#E7A03C",
                        },
                      }}
                      getAriaLabel={() => "Temperature range"}
                      defaultValue={30}
                      valueLabelDisplay="auto"
                      value={value}
                      Slider
                      onChange={handleChange}
                      step={stepAmount}
                      marks
                      min={parseFloat(minAmount)}
                      max={parseFloat(maxAmount)}
                    />
                  </Box>
                  <div className="d-flex gap-3">
                    <div
                      className="p-2 rounded w-50"
                      style={{ border: "1px solid rgba(37, 37, 37, 0.32)" }}
                    >
                      ₹ {value[0]}
                    </div>
                    <div
                      className="p-2 rounded w-50"
                      style={{ border: "1px solid rgba(37, 37, 37, 0.32)" }}
                    >
                      ₹ {value[1]}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PrimaryBtn
              className="w-100"
              onClick={() => {
                handleFilter();
                if (categoryIds) {
                  toggleSavingsPopUp();
                }
                if (currentWidowSize > 768) {
                  toggleFilterMenu();
                }
              }}
            >
              Search
            </PrimaryBtn>
          </div>
        </div>
      </>
    );
  }
);

export default FilterForm;
