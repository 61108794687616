import React, { memo } from "react";
import VarifiedBadge from "./VarifiedBadge";

const VideoCard = memo(({ item, openModal, className }) => {
  return (
    <div
      className={`overflow-hidden p-3 d-flex flex-column gap-3 rounded-4 shadow bg-light ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        openModal(item);
      }}
    >
      <div className="video position-relative rounded-3 overflow-hidden">
        {/* <div className="pid position-absolute bg-light m-2 px-2 py-1 rounded d-flex align-items-center" style={{color: "#3EB938"}}>
          ID: {item.inventroyId}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#3EB938"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="lucide lucide-shield-check"
          >
            <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
            <path d="m9 12 2 2 4-4" />
          </svg>
          Verified
        </div> */}
        <VarifiedBadge />
        <img
          src="Assets/image/playbutton2.svg"
          alt=""
          className="position-absolute top-50 start-50"
          style={{ transform: "translate(-50%, -50%)" }}
          width={50}
        />
        <img
          src={
            item.image !== ""
              ? "https://bookmyqrcode.com/home_rental/" + item.image
              : "Assets/image/Group 168.png"
          }
          alt=""
          className="rounded"
          style={{ width: "100%", objectFit: "cover", height: 400 }}
        />
      </div>
      <div className="d-flex gap-3">
        <div
          className="p-2 rounded w-50 text-center"
          style={{
            border: "1px solid rgba(37, 37, 37, 0.32)",
            borderRadius: 9.21,
            fontSize: 14,
          }}
        >
          <div className="fw-semibold">Rent</div>
          <div>
            <span className="fw-semibold">{item.rent}</span>
            /Month
          </div>
        </div>
        <div
          className="p-2 w-50 text-center"
          style={{
            border: "1px solid rgba(37, 37, 37, 0.32)",
            borderRadius: 9.21,
            fontSize: 14,
          }}
        >
          <div className="fw-semibold">Furnishing</div>
          <div>Fully Furnished</div>
        </div>
      </div>

      <button
        className="btn text-white"
        // disabled={formSubmitted}
        style={{
          backgroundColor: "#E7A03C",
        }}
        // onClick={() => {
        //   // SendData();
        // }}
      >
        {/* {formSubmitted ? " Submitted" : "Interested"} */}
        View Property
      </button>
    </div>
    // <div
    //   className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-4 p-2 modalvideo"
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     openModal(item);
    //   }}
    // >
    //   <div
    //     className="video-container position-relative d-flex align-items-center justify-content-center"
    //     style={{
    //       height: "100%",
    //       borderRadius: "10px",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <img
    //       className=""
    //       src={
    //         item.image !== ""
    //           ? "https://bookmyqrcode.com/home_rental/" + item.image
    //           : "Assets/image/Group 168.png"
    //       }
    //       alt=""
    //     />
    //     <div className="play-button-container">
    //       <img
    //         className="img-fluid play-button"
    //         src="Assets/image/playbutton.png"
    //         alt=""
    //       />
    //     </div>
    //     <div className="col-md-3 col-5 p-0  position-absolute top-0 start-0 titelposter d-flex align-items-center justify-content-center">
    //       <p
    //         style={{ fontSize: "12px" }}
    //         className="p-0 m-0  mb-0 text-start p-2 videosTitleid "
    //       >
    //         {item.inventroyId}
    //       </p>
    //     </div>

    //   </div>
    // </div>
  );
});

export default VideoCard;

// {/* <div className="h6-container position-absolute bottom-0 start-0 titelpostersecond d-flex align-items-center justify-content-center">
//   <div style={{ width: "100%" }}>
//     {isSmallScreen ? (
//       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 320">
//         <path
//           fill="#F39C12"
//           fillOpacity="1"
//           d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
//         ></path>
//         <text
//           x="50%"
//           y="30%"
//           dominant-baseline="middle"
//           text-anchor="middle"
//           fill="white"
//           fontSize="80"
//         >
//           <tspan dy="100">{item.title}</tspan>{" "}
//           {/* Adjust dy to change the padding */}
//         </text>{" "}
//       </svg>
//     ) : (
//       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
//         <path
//           fill="#F39C12"
//           fillOpacity="1"
//           d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
//         ></path>
//         <text
//           x="50%"
//           y="30%"
//           dominant-baseline="middle"
//           text-anchor="middle"
//           fill="white"
//           fontSize="80"
//         >
//           <tspan dy="100">{item.title}</tspan>{" "}
//           {/* Adjust dy to change the padding */}
//         </text>{" "}
//       </svg>
//     )}
//   </div>
// </div>; */}
