import React, { memo } from "react";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import { borderRadius, padding } from "@mui/system";

const SavingPopUp = memo(({ type, savings, closePopUp }) => {
  console.log("type: ", type);
  console.log("savings: ", savings);
  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{ inset: 0, backgroundColor: "rgba(0,0,0,0.5)", zIndex: 20 }}
    >
      <div
        className="overflow-hidden bg-white"
        style={{
          borderRadius: 27.2,
          width: 356,
          maxWidth: "90vw",
          transform: "translate(-50% -50%)",
        }}
      >
        <img
          src="/Assets/image/piggyBank.png"
          alt="piggy bank"
          className="ms-auto d-block"
          style={{ width: "80%" }}
        />
        <div className="p-3 text-center">
          <div className="d-flex flex-column gap-0 fw-bold">
            <p className="m-0" style={{ fontSize: 31.87 }}>
              In {type} you are
            </p>
            <p className="m-0" style={{ fontSize: 36 }}>
              Saving Rs {savings}
            </p>
          </div>
          <p style={{ fontSize: 18 }}>
            {type.toLowerCase() == "3 bhk" ||
            type.toLowerCase() == "4 bhk" ||
            type.toLowerCase() == "2 bhk" ? (
              <>
                We connect you with <strong className="text-dark">owners and brokers</strong>, owners charge no fees,
                and{" "}
                <strong className="text-danger">
                  brokers receive their brokerage from their respective owners
                </strong>
                .
              </>
            ) : (
              <>
                With NO BROKERAGE, We will directly connect you to the owner!!
              </>
            )}
          </p>
          <PrimaryBtn
            className="w-100"
            style={{ borderRadius: 11.5, padding: 9.58 }}
            onClick={closePopUp}
          >
            Continue
          </PrimaryBtn>
        </div>
      </div>
    </div>
  );
});

export default SavingPopUp;
