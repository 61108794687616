import React, { memo, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import { minNumberOfOrdersAllowedToGetOwnerDetails } from "../../constants/pricingDetails";

const WishListBtn = memo(({ count }) => {
  const { data } = useContext(wishlistItemCount);
  const navigate = useNavigate();
  return (
    <button
      className={`btn btn-danger ${
        count > 0 ? "p-3" : "p-1"
      } ml-4 rounded-pill d-flex align-items-center position-relative pe-2 gap-2 tooltip-container`}
      onClick={() => navigate("/checkout")}
    >
      {count > 0 ? (
        <span
          className={`bg-dark text-white position-absolute top-0 start-0`}
          style={{
            width: "2rem",
            transform: "translate(-25%, -25%)",
            aspectRatio: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
          }}
        >
          {count}
        </span>
      ) : (
        <div className="border border-light p-2 rounded-circle hstack align-itmes-center">
          <img src="/Assets/icons/weui_shop-filled.svg" alt="" />
        </div>
      )}
      <div className="hstack align-items-center">
        Get Owner's Details
        <img
          src="/Assets/icons/RightArrow.svg"
          alt=""
          width="20"
          height="20"
          className="ms-2"
        />
      </div>
    </button>
  );
});

export default WishListBtn;
